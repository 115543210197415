import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const logout = (event) => {
	// console.log("hello from logout ")
	// Remove user-related data from localStorage
	localStorage.removeItem('token');
	localStorage.removeItem('phoneNumber');
	localStorage.removeItem('refreshTokenExpiration');
	localStorage.removeItem('user');

	// navigate to the login page
	window.location.href = '/AdminLoginPage';
};
const SideBar = () => {
	return (
		<div
			className="app-sidebar menu-fixed expanded d-flex flex-column justify-content-between"
			data-background-color="man-of-steel"
			data-image="../../app-assets/img/sidebar-bg/01.jpg"
			data-scroll-to-active="true"
		>
			<div className="sidebar-header">
				<div className="logo clearfix">
					<Link className="logo-text float-left d-flex align-items-center" to="/">
						<div className="logo-img">
							<img
								className="w-100 h-100"
								src="../../../main-assets/imgs/mainLogoDark.jpg"
								alt="Apex Logo"
							/>
						</div>
						<span className="text">Smart-Menu</span>
					</Link>
					<a
						className="nav-toggle d-none d-lg-none d-xl-block"
						id="sidebarToggle"
						href="javascript:;"
					>
						<i className="toggle-icon ft-toggle-right" data-toggle="expanded" />
					</a>
					<a
						className="nav-close d-block d-lg-block d-xl-none"
						id="sidebarClose"
						href="javascript:;"
					>
						<i className="ft-x" />
					</a>
				</div>
			</div>

			<div className="sidebar-content main-menu-content">
				<div className="nav-container">
					<ul
						className="navigation navigation-main"
						id="main-menu-navigation"
						data-menu="menu-navigation"
					>
						<li className="nav-item">
							<NavLink to="/" activeClassName="active" exact>
								<i class="fa-solid fa-house icon" />

								<span className="menu-title" data-i18n="Email">
									الصفحة الرئسية
								</span>
							</NavLink>
						</li>

						{/* <li className=" nav-item "><Link to='/WorkersPage'><i className="ft-mail"></i><span className="menu-title" data-i18n="Email">العمال</span></Link>
            </li> */}

						<li className=" nav-item ">
							<NavLink to="/WorkersAccountPage" activeClassName="active">
								<i class="fa-solid fa-users icon" />
								<span className="menu-title" data-i18n="Email">
									حسابات العمال
								</span>
							</NavLink>
						</li>

						<li className=" nav-item ">
							<NavLink to="/AdminRolesPage" activeClassName="active">
								<i class="fa-solid fa-shield icon" />
								<span className="menu-title" data-i18n="Email">
									الأدوار
								</span>
							</NavLink>
						</li>

						<li className=" nav-item ">
							<NavLink to="/AdminCategoriesPage" activeClassName="active">
								<i class="fa-solid fa-utensils icon" />
								<span className="menu-title" data-i18n="Email">
									الأصناف
								</span>
							</NavLink>
						</li>

						<li className=" nav-item ">
							<NavLink to="/AdminTablesPage" activeClassName="active">
								<i class="fa-solid fa-chair icon" />
								<span className="menu-title" data-i18n="Email">
									الطاولات{' '}
								</span>
							</NavLink>
						</li>

						<li className=" nav-item " activeClassName="active">
							<NavLink to="/AdminBillsPage">
								<i class="fa-solid fa-file-invoice icon" />
								<span className="menu-title" data-i18n="Email">
									الفواتير{' '}
								</span>
							</NavLink>
						</li>

						<li className="nav-item">
							<NavLink to="/ProdutsTablePage" activeClassName="active">
								<i class="fa-solid fa-bowl-food icon" />
								<span className="menu-title" data-i18n="Email">
									الوجبات
								</span>
							</NavLink>
						</li>
                        
                        
						<li className=" nav-item ">
							<NavLink to="/OrderOnlinPage" activeClassName="active">
								<i class="fa-solid fa-globe icon" />
								<span className="menu-title" data-i18n="Email">
									الطلبات
								</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</div>

			<button onClick={logout} class="cssbuttons-io-button">
				<span className="textLogOut">تسجيل الخروج</span>
				<div class="icon">
					<svg
						height="24"
						width="24"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 0h24v24H0z" fill="none" />
						<path
							d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
							fill="currentColor"
						/>
					</svg>
				</div>
			</button>

			<div className="sidebar-background" />
		</div>
	);
};

export default SideBar;

/*                 <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-home"></i><span className="menu-title" data-i18n="Dashboard">Dashboard</span><span className="tag badge badge-pill badge-danger float-right mr-1 mt-1">2</span></a>
                    <ul className="menu-content">
                        <li className=""><a href="dashboard1.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Dashboard 1">Dashboard 1</span></a>
                        </li>
                        <li><a href="dashboard2.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Dashboard 2">Dashboard 2</span></a>
                        </li>
                    </ul>
                </li>
                <li className=" nav-item"><a href="app-email.html"><i className="ft-mail"></i><span className="menu-title" data-i18n="Email">Email</span></a>
                </li>
                <li className=" nav-item"><a href="app-chat.html"><i className="ft-message-square"></i><span className="menu-title" data-i18n="Chat">Chat</span></a>
                </li>
                <li className=" nav-item"><a href="app-taskboard.html"><i className="ft-file-text"></i><span className="menu-title" data-i18n="Task Board">Task Board</span></a>
                </li>
                <li className=" nav-item"><a href="app-calendar.html"><i className="ft-calendar"></i><span className="menu-title" data-i18n="Calendar">Calendar</span></a>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-aperture"></i><span className="menu-title" data-i18n="UI Kit">UI Kit</span><span className="tag badge badge-pill badge-success float-right mr-1 mt-1">6</span></a>
                    <ul className="menu-content">
                        <li><a href="grids.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Grid">Grid</span></a>
                        </li>
                        <li><a href="typography.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Typography">Typography</span></a>
                        </li>
                        <li><a href="syntax-highlighter.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Syntax Highlighter">Syntax Highlighter</span></a>
                        </li>
                        <li><a href="helper-classNamees.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Helper classNamees">Helper classNamees</span></a>
                        </li>
                        <li><a href="text-utilities.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Text Utilities">Text Utilities</span></a>
                        </li>
                        <li><a href="color-palette.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Color Palette">Color Palette</span></a>
                        </li>
                        <li className="has-sub"><a href="javascript:;"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Icons">Icons</span></a>
                            <ul className="menu-content">
                                <li><a href="icons-feather.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Feather Icon">Feather Icon</span></a>
                                </li>
                                <li><a href="icons-font-awesome.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Font Awesome Icon">Font Awesome Icon</span></a>
                                </li>
                                <li><a href="icons-simple-line.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Simple Line Icon">Simple Line Icon</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-box"></i><span className="menu-title" data-i18n="Components">Components</span></a>
                    <ul className="menu-content">
                        <li className="has-sub"><a href="javascript:;"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Bootstrap">Bootstrap</span></a>
                            <ul className="menu-content">
                                <li><a href="components-buttons.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Buttons">Buttons</span></a>
                                </li>
                                <li><a href="components-alerts.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Alerts">Alerts</span></a>
                                </li>
                                <li><a href="components-badges.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Badges">Badges</span></a>
                                </li>
                                <li><a href="components-dropdowns.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Dropdowns">Dropdowns</span></a>
                                </li>
                                <li><a href="components-media-objects.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Media Objects">Media Objects</span></a>
                                </li>
                                <li><a href="components-pagination.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Pagination">Pagination</span></a>
                                </li>
                                <li><a href="components-progress.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Progress Bars">Progress Bars</span></a>
                                </li>
                                <li><a href="components-modals.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Modals">Modals</span></a>
                                </li>
                                <li><a href="components-collapse.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Collapse">Collapse</span></a>
                                </li>
                                <li><a href="components-lists.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="List">List</span></a>
                                </li>
                                <li><a href="components-carousel.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Carousel">Carousel</span></a>
                                </li>
                                <li><a href="components-popover.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Popover">Popover</span></a>
                                </li>
                                <li><a href="components-tabs.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Tabs">Tabs</span></a>
                                </li>
                                <li><a href="components-tooltip.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Tooltip">Tooltip</span></a>
                                </li>
                                <li><a href="components-spinner.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Spinner">Spinner</span></a>
                                </li>
                                <li><a href="components-toast.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Toast">Toast</span></a>
                                </li>
                            </ul>
                        </li>
                        <li className="has-sub"><a href="javascript:;"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Extra">Extra</span></a>
                            <ul className="menu-content">
                                <li><a href="ex-component-sweet-alerts.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Sweet Alert">Sweet Alert</span></a>
                                </li>
                                <li><a href="ex-component-toastr.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Toastr">Toastr</span></a>
                                </li>
                                <li><a href="ex-component-nouislider.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="NoUI Slider">NoUI Slider</span></a>
                                </li>
                                <li><a href="ex-component-upload.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Upload">Upload</span></a>
                                </li>
                                <li><a href="ex-component-dragndrop.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Drag and Drop">Drag and Drop</span></a>
                                </li>
                                <li><a href="ex-component-tour.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Tour">Tour</span></a>
                                </li>
                                <li><a href="ex-component-media-player.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Media Player">Media Player</span></a>
                                </li>
                                <li><a href="ex-component-treeview.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Treeview">Treeview</span></a>
                                </li>
                                <li><a href="ex-component-swiper.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Swiper">Swiper</span></a>
                                </li>
                                <li><a href="ex-component-miscellaneous.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Miscellaneous">Miscellaneous</span></a>
                                </li>
                                <li><a href="ex-component-avatar.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Avatar">Avatar</span></a>
                                </li>
                                <li><a href="ex-component-image-cropper.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Image Cropper">Image Cropper</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-edit"></i><span className="menu-title" data-i18n="Forms">Forms</span></a>
                    <ul className="menu-content">
                        <li className="has-sub"><a href="javascript:;"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Elements">Elements</span></a>
                            <ul className="menu-content">
                                <li><a href="form-inputs.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Inputs">Inputs</span></a>
                                </li>
                                <li><a href="form-input-groups.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Input Groups">Input Groups</span></a>
                                </li>
                                <li><a href="form-radio.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Radio">Radio</span></a>
                                </li>
                                <li><a href="form-checkbox.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Checkbox">Checkbox</span></a>
                                </li>
                                <li><a href="form-switch.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Switch">Switch</span></a>
                                </li>
                                <li><a href="form-select.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Select">Select</span></a>
                                </li>
                                <li><a href="form-editor.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Editor">Editor</span></a>
                                </li>
                                <li><a href="form-input-tags.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Input Tag">Input Tag</span></a>
                                </li>
                                <li><a href="form-datetimepicker.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Datepicker">Datepicker</span></a>
                                </li>
                            </ul>
                        </li>
                        <li><a href="form-layout.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Layouts">Layouts</span></a>
                        </li>
                        <li><a href="form-validation.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Validation">Validation</span></a>
                        </li>
                        <li><a href="form-wizard.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Wizard">Wizard</span></a>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-grid"></i><span className="menu-title" data-i18n="Tables">Tables</span></a>
                    <ul className="menu-content">
                        <li><a href="table-basic.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Basic">Basic</span></a>
                        </li>
                        <li><a href="table-extended.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Extended">Extended</span></a>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-layout"></i><span className="menu-title" data-i18n="Data Tables">Data Tables</span></a>
                    <ul className="menu-content">
                        <li><a href="dt-basic-initialization.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Basic">Basic</span></a>
                        </li>
                        <li><a href="dt-advanced-initialization.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Advanced">Advanced</span></a>
                        </li>
                        <li><a href="dt-data-sources.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Data Sources">Data Sources</span></a>
                        </li>
                        <li><a href="dt-api.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="API">API</span></a>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-layers"></i><span className="menu-title" data-i18n="Cards">Cards</span></a>
                    <ul className="menu-content">
                        <li><a href="cards-basic.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Basic Cards">Basic Cards</span></a>
                        </li>
                        <li><a href="cards-advanced.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Advanced Cards">Advanced Cards</span></a>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-map"></i><span className="menu-title" data-i18n="Maps">Maps</span></a>
                    <ul className="menu-content">
                        <li><a href="map-leaflet.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Leaflet Maps">Leaflet Maps</span></a>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-bar-chart-2"></i><span className="menu-title" data-i18n="Charts">Charts</span></a>
                    <ul className="menu-content">
                        <li><a href="charts-apex.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Apex Charts">Apex Charts</span></a>
                        </li>
                        <li><a href="charts-chartjs.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="ChartJs">ChartJs</span></a>
                        </li>
                        <li><a href="charts-chartist.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Chartist">Chartist</span></a>
                        </li>
                    </ul>
                </li>
                <li className="has-sub nav-item"><a href="javascript:;"><i className="ft-copy"></i><span className="menu-title" data-i18n="Pages">Pages</span></a>
                    <ul className="menu-content">
                        <li className="has-sub"><a href="javascript:;"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Authentication">Authentication</span></a>
                            <ul className="menu-content">
                                <li><a href="auth-forgot-password.html" target="_blank"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Forgot Password">Forgot Password</span></a>
                                </li>
                                <li><a href="auth-login.html" target="_blank"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Login">Login</span></a>
                                </li>
                                <li><a href="auth-register.html" target="_blank"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Register">Register</span></a>
                                </li>
                                <li><a href="auth-lock-screen.html" target="_blank"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Lock Screen">Lock Screen</span></a>
                                </li>
                            </ul>
                        </li>
                        <li><a href="timeline-horizontal.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Horizontal Timeline">Horizontal Timeline</span></a>
                        </li>
                        <li className="has-sub"><a href="javascript:;"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Vertical Timeline">Vertical Timeline</span></a>
                            <ul className="menu-content">
                                <li><a href="timeline-vertical-center.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Center">Center</span></a>
                                </li>
                                <li><a href="timeline-vertical-left.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Left">Left</span></a>
                                </li>
                                <li><a href="timeline-vertical-right.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Right">Right</span></a>
                                </li>
                            </ul>
                        </li>
                        <li className="has-sub"><a href="javascript:;"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Users">Users</span></a>
                            <ul className="menu-content">
                                <li><a href="page-users-list.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="List">List</span></a>
                                </li>
                                <li><a href="page-users-view.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="View">View</span></a>
                                </li>
                                <li><a href="page-users-edit.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Edit">Edit</span></a>
                                </li>
                            </ul>
                        </li>
                        <li><a href="page-account-settings.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Account Settings">Account Settings</span></a>
                        </li>
                        <li><a href="page-user-profile.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="User Profile">User Profile</span></a>
                        </li>
                        <li><a href="page-invoice.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Invoice">Invoice</span></a>
                        </li>
                        <li><a href="page-error.html" target="_blank"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Error">Error</span></a>
                        </li>
                        <li><a href="page-coming-soon.html" target="_blank"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Coming Soon">Coming Soon</span></a>
                        </li>
                        <li><a href="page-maintenance.html" target="_blank"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Maintenance">Maintenance</span></a>
                        </li>
                        <li><a href="page-gallery.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Gallery">Gallery</span></a>
                        </li>
                        <li><a href="page-search.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Search">Search</span></a>
                        </li>
                        <li><a href="page-faq.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="FAQ">FAQ</span></a>
                        </li>
                        <li><a href="page-knowledge-base.html"><i className="ft-arrow-right submenu-icon"></i><span className="menu-item" data-i18n="Knowledge Base">Knowledge Base</span></a>
                        </li>
                    </ul>
                </li>
                <li className=" nav-item"><a href="https://pixinvent.com/apex-angular-4-bootstrap-admin-template/html-documentation" target="_blank"><i className="ft-book"></i><span className="menu-title" data-i18n="Documentation">Documentation</span></a>
                </li>
                <li className=" nav-item"><a href="https://pixinvent.ticksy.com/" target="_blank"><i className="ft-life-buoy"></i><span className="menu-title" data-i18n="Support">Support</span></a>
                </li> */
