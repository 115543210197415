import React, { useEffect, useState } from 'react';

import BigCardCOM from './../../Components/sheardComponent/BigCardCOM';
import DateTableWithImg from './../../Components/sheardComponent/DateTableWithImg';
import { Link } from 'react-router-dom';

import { ConfirmDeleteComp } from '../../Components/sheardComponent/ConfirmDeleteComp';
import { FixUpdateDataTableHook } from '../../Components/sheardComponent/FixUpdateDataTableHook';
import { ChangStateComp } from '../../Components/sheardComponent/ChangStateComp';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import MainLoaderComp from '../../Components/sheardComponent/MainLoaderComp';
import UserHook from '../../hook/users/user-hook';
import RoleHook from '../../hook/roles/role-hook';
import AddPopupModal from '../../Components/sheardComponent/AddPopupModal';
import { ChangeFreezeCom } from '../../Components/sheardComponent/ChangeFreezeCom';


export default function WorkersAccountPage() {

	const [isUpdate, userId, name, userName, phone, email, newPass, oldPass,confirmPass, userRole, resGetUser, resChangUserPassword, onSelectUserId, onChangeName, onEditName, onChangeUserName, onEditUserName, onChangePhone, onEditPhone ,onChangeEmail, onEditEmail, onChandeNewPass, onChandeOldPass,onChandeConfirmPass, onEdituserRole,  OnDeleteUser, onChangeState , OnUpdateUser, OnChangeUserRole, OnChangeUserPassword]   = UserHook();
	const [ name2, onChangeName2, resGetRoles, OnSubmit, isCreated, OnDeleteRole ] = RoleHook();
		// تعديه اول شي المتغير لي فيه البيانات حق الداتا تيبل و ثاني برمتر سلكتور الجدول نفسه
	FixUpdateDataTableHook(resGetUser, '.dom-jQuery-events');
	// FixUpdateDataTableHook(resGetRoles, '.dom-jQuery-events');

  // اضهار واخفاء الرمز
//   const [showPassword, setShowPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };







	return (
		<div>
			{<BigCardCOM />}

			<div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
				<Link
					to="/AdminRegisterPage"
					className="main-btn"
					style={{ padding: '7px 35px', fontSize: '18px', fontWeight: 'bold' }}
				>
					<span>اضافة حساب</span>
					<div className="svg-wrapper-1">
						<div className="svg-wrapper">
							<i className="fa-solid fa-circle-plus" />
						</div>
					</div>
				</Link>

			</div>

			<div className="table-responsive my-4">
				{
					resGetUser != null ?(
						<table className="table table-striped table-bordered dom-jQuery-events">
						<thead>
							<tr>
								<th>
									الاسم
								</th>
								<th>
									الايميل
								</th>
								<th>
									اسم المستخدم
								</th>
								<th>
									الرقم
								</th>
								<th>
									كلمة المرور
								</th>
								<th>
									مجمد
								</th>
								<th>
									الصلاحية
								</th>
								<th>
									الإجرائات
								</th> 
							</tr>
						</thead>
						<tbody>{
							resGetUser?.data.map((user, index)=>{
								return(
									<tr>
									<td><span>
										{user.name}
									</span></td>
									<td><span>
										{user.email != null?(user.email):("لايوجد ايميل")}
									</span></td>
									<td><span>
										{user.userName != null?(user.userName):("لايوجد اسم مستخدم")}
									</span></td>
									<td><span>
										{user.phoneNumber}
									</span></td>
									<td>
										<span>
										********
									</span></td>
									<td>
									{user.isEnabled ? (
										<i  className="fa-regular fa-circle-check statusData statusActive freezeIcon" />
									) : (
										<i className="fa-regular fa-circle-xmark statusData statusNActive" />
									)}
								</td>
									<td>
										<span>
											{user.roles[0]}
										</span>
									</td>
									<td>
										<div className="groupOptions">
											<button
												className="edit-post info"
												data-toggle="modal"
												data-target="#ChangePassIconForm"
												onClick={() =>{
													onSelectUserId(user.id);
												}}
											>
												<span className="edit-tooltip">تغيير كلمة المرور</span>
												<span className="edit-icon">
												<i class="fa-solid fa-key"></i>
												</span>
											</button>
											<button
												className="edit-post gold"
												data-toggle="modal"
												data-target="#EditRoleIconForm"
												onClick={() =>{
													onSelectUserId(user.id);
													onEdituserRole(user.roles[0])
												}}
											>
												<span className="edit-tooltip">تغيير الصلاحية</span>
												<span className="edit-icon">
													<i class="fa-solid fa-user-pen"></i>
												</span>
											</button>
											<button
												className="edit-post gold"
												data-toggle="modal"
												data-target="#EditIconForm"
												onClick={() =>{
													onSelectUserId(user.id);
													onEditName(user.name);
													onEditUserName(user.userName);
													onEditEmail(user.email);
													onEditPhone(user.phoneNumber);
												}}
											>
												<span className="edit-tooltip">تعديل</span>
												<span className="edit-icon">
													<i className="ft-edit-2" />
												</span>
											</button>
											<ChangeFreezeCom onChangeStateFun={OnDeleteUser} nameItem={user.name} idItem={user.id}/>
										</div>
									</td>
								</tr>
								)
							})
						}
						</tbody>
					</table>
					):( <MainLoaderComp/>)
				}
			</div>

			
			{
				//editPupUp Modal
			}
			<div
				className="modal fade text-left"
				id="EditIconForm"
				tabindex="-1"
				role="dialog"
				aria-labelledby="myModalLabel34"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="myModalLabel34">
								تعديل المستخدم
							</h3>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">
									<i className="ft-x font-medium-2 text-bold-700" />
								</span>
							</button>
						</div>
							<div className="modal-body">
								<div className="">
									<div className="form-group mb-2">
										<label>الاسم</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												// data-validation-required-message="هذا الحقل مطلوب"
												// placeholder="رقم الطاولة"
												value={name}
												onChange={onChangeName}
											/>
										</div>
									</div>
									<div className="form-group mb-2">
										<label>اسم المستخدم</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												// data-validation-required-message="هذا الحقل مطلوب"
												// placeholder="رقم الطاولة"
												value={userName}
												onChange={onChangeUserName}
											/>
										</div>
									</div>
									<div className="form-group mb-2">
										<label>الايميل</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												// data-validation-required-message="هذا الحقل مطلوب"
												// placeholder="رقم الطاولة"
												value={email}
												onChange={onChangeEmail}
											/>
										</div>
									</div>
									<div className="form-group mb-2">
										<label>الرقم</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												// data-validation-required-message="هذا الحقل مطلوب"
												// placeholder="رقم الطاولة"
												value={phone}
												onChange={onChangePhone}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<input
									type="reset"
									className="btn bg-light-secondary"
									data-dismiss="modal"
									value="الغاء"
								/>
								{!isUpdate ? (
									<button onClick={OnUpdateUser} className="btn btn-primary">
										تأكيد
									</button>
								) : (<BtnLoaderComp/>) 
								}
								
							</div>
					</div>
				</div>
			</div>



			{
				//editRolePupUp Modal
				// console.log(resGetRoles?.data.data)
			}
			
			<div
			className="modal fade text-left"
			id="EditRoleIconForm"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myModalLabel34"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" id="myModalLabel34">
							تعديل صلاحية المستخدم
						</h3>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">
								<i className="ft-x font-medium-2 text-bold-700" />
							</span>
						</button>
					</div>
						<div className="modal-body">
							<div className="">
								<div className="form-group mb-2">
									<label>صلاحية المستخدم</label>
									<select id="basic-form-6"
									name="interested"
									className="form-control"
									onChange={(e)=>{
										onEdituserRole(e.target.value);
									}}
									> 
										<option value="none" selected="" disabled="">
											{userRole} الصلاحية الحالية :
										</option>
										{
											resGetRoles ? (
												resGetRoles.data.data.map((role, index) => {
													return (
														<option key={role.id} value={role.roleName}>
															{role.roleName}
														</option>
													);
												})
											) : ("none")
										}
									</select>

								</div>
							</div>
						</div>
						<div className="modal-footer">
							<input
								type="reset"
								className="btn bg-light-secondary"
								data-dismiss="modal"
								value="الغاء"
							/>
							{!isUpdate ? (
								<button onClick={OnChangeUserRole} className="btn btn-primary">
									تأكيد
									</button>
							) : (<BtnLoaderComp/>) 
							}
							
						</div>
				</div>
			</div>
		</div>
						

					
		{
			//changePassPupUp Modal
		}
		<div
			className="modal fade text-left"
			id="ChangePassIconForm"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myModalLabel34"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" id="myModalLabel34">
							تغيير كلمة المرور
						</h3>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">
								<i className="ft-x font-medium-2 text-bold-700" />
							</span>
						</button>
					</div>
						<div className="modal-body">
							<div className="">
								<div className="form-group mb-2">
									<label>كلمة المرور القديمة</label>
									<div className="controls">
										<input
											type="text"
											name="text"
											className="form-control"
											// data-validation-required-message="هذا الحقل مطلوب"
											// placeholder="رقم الطاولة"
											value={oldPass}
											onChange={onChandeOldPass}
										/>
									</div>
								</div>
								<div className="form-group mb-2">
									<label>كلمة المرور الجديده</label>
									<div className="controls">
										<input
											type="text"
											name="text"
											className="form-control"
											// data-validation-required-message="هذا الحقل مطلوب"
											// placeholder="رقم الطاولة"
											value={newPass}
											onChange={onChandeNewPass}
										/>
									</div>
								</div>
								<div className="form-group mb-2">
									<label>تاكيد كلمة المرور</label>
									<div className="controls">
										<input
											type="text"
											name="text"
											className="form-control"
											// data-validation-required-message="هذا الحقل مطلوب"
											// placeholder="رقم الطاولة"
											value={confirmPass}
											onChange={onChandeConfirmPass}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<input
								type="reset"
								className="btn bg-light-secondary"
								data-dismiss="modal"
								value="الغاء"
							/>
							{!isUpdate ? (
								<button onClick={OnChangeUserPassword} className="btn btn-primary">
									تأكيد
								</button>
							) : (<BtnLoaderComp/>) 
							}
							
						</div>
				</div>
			</div>
		</div>





		</div>
	);
}

{
	// ثم، يمكنك استخدام حالة التحميل لعرض مؤشر التحميل أو البيانات، حسب الحالة
	// loading ? (
	// 	<div
	// 	className="modal fade text-left"
	// 	id="EditRoleIconForm"
	// 	tabindex="-1"
	// 	role="dialog"
	// 	aria-labelledby="myModalLabel34"
	// 	aria-hidden="true"
	// >
	// 	<div className="modal-dialog" role="document">
	// 		<div className="modal-content">
	// 			<div className="modal-header">
	// 				<h3 className="modal-title" id="myModalLabel34">
	// 					تعديل صلاحية المستخدم
	// 				</h3>
	// 				<button
	// 					type="button"
	// 					className="close"
	// 					data-dismiss="modal"
	// 					aria-label="Close"
	// 				>
	// 					<span aria-hidden="true">
	// 						<i className="ft-x font-medium-2 text-bold-700" />
	// 					</span>
	// 				</button>
	// 			</div>
	// 				<div className="modal-body">
	// 					<div className="">
	// 						<div className="form-group mb-2">
	// 							<label>صلاحية المستخدم</label>
	// 							<select
	// 								id="basic-form-6"
	// 								name="interested"
	// 								className="form-control"
	// 							>
	// 								<option value="none" selected="" disabled="">
	// 									{userRole} الصلاحية الحالية :
	// 								</option>
	// 								{
	// 									resGetRoles?(												resGetRoles?.data.data.map((role) => {
	// 										return (
	// 											resGetRoles?.data.data.map((role,index) =>{
	// 											<option key={role.id} value={role.name}>
	// 												{role.name}
	// 											</option>})
	// 										);
	// 									})):("none")

	// 								}
	// 							</select>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div className="modal-footer">
	// 					<input
	// 						type="reset"
	// 						className="btn bg-light-secondary"
	// 						data-dismiss="modal"
	// 						value="الغاء"
	// 					/>
	// 					{isUpdate ? (
	// 						<button onClick={OnChangeUserRole} className="btn btn-primary">
	// 							تأكيد
	// 						</button>
	// 					) : (<BtnLoaderComp/>) 
	// 					}
						
	// 				</div>
	// 		</div>
	// 	</div>
	// </div>
	// ):(<MainLoaderComp/>)
}

// فيلد الرمز
// <td>
// <span>
// 	<fieldset className=" position-relative has-icon-right">
// 		<input
// type={showPassword ? 'text' : 'password'}
// 			className="form-control"
// 			id="iconLeft2"
// 			placeholder="كلمة المرور"
// value={7777}
// 		/>
// <div className="form-control-position cursor-pointer" onClick={togglePasswordVisibility}>
// <i  className={`fa-regular fa-eye`}></i>

// </div>
// 	</fieldset>
// </span>
// </td>


// جدول لاعادة الاستخدام

// <div className="table-responsive my-4">
// <table className="table table-striped table-bordered dom-jQuery-events">
//   <thead>
//     <tr>
//       <th>
//         Name<span>الاسم</span>
//       </th>
//       <th>
//         Number<span>الرقم</span>
//       </th>
//       <th>
//         Password<span>كلمة المرور</span>
//       </th>
//       <th>
//         P<span>الحالة</span>
//       </th>
//       <th>
//         Pirmation<span>الصلاحية</span>
//       </th>
//       <th>
//         Sa<span>الإجرائات</span>
//       </th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td>
//         <span>برست</span>
//       </td>
//       <td>
//         <span>779891026</span>
//       </td>
//       <td>
//         <span>
//           <fieldset className=" position-relative has-icon-right">
//             <input
//               type="text"
//               className="form-control"
//               id="iconLeft2"
//               placeholder="Icon Right, Default Input"
//             />
//             <div className="form-control-position">
//               <i className="ft-file" />
//             </div>
//           </fieldset>
//         </span>
//       </td>
//       <td>
//         {true ? (
//           <i className="fa-regular fa-circle-check statusData statusActive" />
//         ) : (
//           <i className="fa-regular fa-circle-xmark statusData statusNActive" />
//         )}
//       </td>
//       <td>
//         <span>
          
//           <select className="form-control" id="basicSelect">
//             <option>IT</option>
//             <option>Blade Runner</option>
//             <option>Thor Ragnarok</option>
//           </select>
//         </span>
//       </td>
//       <td>
//         <div className="groupOptions">
//           <div className="edit-post success confirm-text">
//             <span className="edit-tooltip ">تغيير الحالة</span>
//             <span className="edit-icon">
//               <i className="fa-solid fa-circle-check" />
//             </span>
//           </div>
//           <button
//             className="edit-post gold"
//             data-toggle="modal"
//             data-target="#iconForm"
//           >
//             <span className="edit-tooltip">تعديل</span>
//             <span className="edit-icon">
//               <i className="ft-edit-2" />
//             </span>
//           </button>
//           <button className="edit-post red confirm-text">
//             <span className="edit-tooltip">حذف</span>
//             <span className="edit-icon">
//               <i className="ft-trash" />
//             </span>
//           </button>
//         </div>
//       </td>
//     </tr>
//   </tbody>
// </table>
// </div>