
import notify from './../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAllBills} from '../../redux/actions/billAction';

const BillHook = () => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const [name, setName] = useState('');

    const onChangeName = (e) => {
        setName(e.target.value)
    }

    const resGetBills = useSelector(state => state.billReducer.getAllBills);

    useEffect(()=>{
        if(loading === false){
            dispatch(getAllBills());       
        }
    }, [loading])

    return [name, onChangeName, resGetBills]
}

export default BillHook