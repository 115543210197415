import React from 'react'

function FoodCategorySlider() {
  return (
    <div className="categoryWrapper">
    <div className="wrapperIcon"><i id="left" className="fa-solid fa-angle-left"></i></div>
    <ul className="tabs-box-wrapper">
      
          <li className="wrapperTab active">
              <input className="radio-input" type="radio" name="engine"/>
                  <span className="radio-tile">
                      <span className="radio-icon">
                          <i className="fa-solid fa-burger"></i>                        </span>
                      <span className="radio-label">Bicycle</span>
                  </span>
          </li>
          <li className="wrapperTab ">
              <input checked="" className="radio-input" type="radio" name="engine"/>
              <span className="radio-tile">
                  <span className="radio-icon">
                      <i className="fa-solid fa-bowl-food"></i>                    </span>
                  <span className="radio-label">Motorbike</span>
              </span>
          </li>
          <li className="wrapperTab ">
              <input className="radio-input" type="radio" name="engine"/>
              <span className="radio-tile">
                  <span className="radio-icon">
                      <i className="fa-solid fa-mug-hot"></i>                    </span>
                  <span className="radio-label">Car</span>
              </span>
          </li>
          <li className="wrapperTab ">
              <input className="radio-input" type="radio" name="engine"/>
              <span className="radio-tile">
                  <span className="radio-icon">
                      <i className="fa-solid fa-mug-hot"></i>                    </span>
                  <span className="radio-label">Car</span>
              </span>
          </li>
          <li className="wrapperTab ">
              <input className="radio-input" type="radio" name="engine"/>
              <span className="radio-tile">
                  <span className="radio-icon">
                      <i className="fa-solid fa-mug-hot"></i>                    </span>
                  <span className="radio-label">Car</span>
              </span>
          </li>
          <li className="wrapperTab ">
              <input className="radio-input" type="radio" name="engine"/>
              <span className="radio-tile">
                  <span className="radio-icon">
                      <i className="fa-solid fa-mug-hot"></i>                    </span>
                  <span className="radio-label">Car</span>
              </span>
          </li>
          <li className="wrapperTab ">
              <input className="radio-input" type="radio" name="engine"/>
              <span className="radio-tile">
                  <span className="radio-icon">
                      <i className="fa-solid fa-mug-hot"></i>                    </span>
                  <span className="radio-label">Car</span>
              </span>
          </li>
    </ul>
    <div className="wrapperIcon"><i id="right" className="fa-solid fa-angle-right"></i></div>
    </div>
  )
}

export default FoodCategorySlider
