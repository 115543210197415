import React from 'react'

const Footer = () => {
  return (
    <footer className="footer undefined undefined">
        <p className="clearfix text-muted m-auto w-50" style={{    width:" fit-content"}}><span>Copyright &copy; 2023 &nbsp;</span><a href="" id="pixinventLink" target="_blank">SMART MENU</a><span className="d-none d-sm-inline-block">, All rights reserved.</span></p>
    </footer>
  )
}

export default Footer
