import React from 'react'
import BigCardCOM from '../../Components/sheardComponent/BigCardCOM'
import OrderOnlinCard from '../../Components/sheardComponent/OrderOnlinCard'
import { Link } from 'react-router-dom'

export default function OrderOnlinPage() {
  return (
    <div>
    <BigCardCOM />

    <div className="productsViewSection">
        <div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
            <form className="d-flex justify-content-center mb-2 mb-sm-0" action="javascript:;">
                <div className="faq-search-input form-group position-relative w-100 m-0">
                    <input
                        type="text"
                        className="form-control round form-control-lg shadow p-3"
                        id="searchbar"
                        placeholder="عن ماذا تبحث..."
                    />
                    <button
                        className="btn btn-primary round position-absolute"
                        type="button"
                        style={{ left: '7px', top: '7px', padding: '6px' }}
                    >
                        <span className="d-none">Search</span>
                        <i className="ft-search d-block" style={{ fontSize: '20px' }} />
                    </button>
                </div>
            </form>

            <div className="btns">
                <Link to="/ProdutsPage" className="btn btn-primary mr-1 mb-1">
                    <i className="fa-solid fa-grip" />
                </Link>
                <a href="/ProdutsTablePage" className="btn btn-outline-primary mr-1 mb-1">
                    <i className="fa-solid fa-list" />
                </a>
            </div>
        </div>

        <div className="viewOrderCards">
            <div className="row">

                <div className="col-6 col-lg-3 col-md-4 col-sm-6 col-phone">
                    <OrderOnlinCard />
                </div>

            </div>
        </div>
    </div>
</div>
  )
}
