import { GET_ALL_USERS, DELETE_USER, CHANGE_USER_STATE, UPDATE_USERS, CHANGE_USER_ROLE, CHANGE_USER_PASSWORD } from "../type"

const inital = {
    roles: [],
}

const userReduser = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                getAllUsers: action.payload,
            }
        case DELETE_USER:
            return {
                ...state,
                deleteUser: action.payload,
            }
        case CHANGE_USER_STATE:
            return {
                ...state,
                changeUserState: action.payload,
            }
        case UPDATE_USERS:
            return {
                ...state,
                updataUser: action.payload, // هنا يتم تحديث الخاصية الجديدة
            }
        case CHANGE_USER_ROLE:
            return{
                ...state,
                changUserRole: action.payload,
            }
        case CHANGE_USER_PASSWORD:
            return{
                ...state,
                changUserPassword: action.payload,
            }
        default:
            return state;
    }
}

export default userReduser
