import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CategoryHook from '../../hook/category/category-hook';

import { ConfirmDeleteComp } from '../../Components/sheardComponent/ConfirmDeleteComp';
import { FixUpdateDataTableHook } from '../../Components/sheardComponent/FixUpdateDataTableHook';
import { ChangStateComp } from '../../Components/sheardComponent/ChangStateComp';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import MainLoaderComp from '../../Components/sheardComponent/MainLoaderComp';

export default function AdminCategoryPage() {

	const [name, onChangeName, resGetCategories, OnSubmit, isCreated, OnDeleteCategory, OnUpdate, isUpadte] = CategoryHook();
    
		// تعديه اول شي المتغير لي فيه البيانات حق الداتا تيبل و ثاني برمتر سلكتور الجدول نفسه
	FixUpdateDataTableHook(resGetCategories, '.dom-jQuery-events');

	const [selectedId, setSelectedId] = useState(null);
	const [newName, setnewName] = useState(null);
    const onChangeNewName = (e) => {
        setnewName(e.target.value)
    }
	const changeName = (e) =>{
		OnUpdate(selectedId,newName)
	}
	return (
		<div>
			<div className="productsViewSection">
				<div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
					<button
						className="main-btn"
						data-toggle="modal"
						data-target="#AddIconForm"
						style={{ padding: '7px 35px', fontSize: '18px', fontWeight: 'bold' }}
					>
						<span>اضافة صنف</span>
						<div className="svg-wrapper-1">
							<div className="svg-wrapper">
								<i className="fa-solid fa-circle-plus" />
							</div>
						</div>
					</button>

					{/* <div className="btns">
						<Link to="/ProdutsPage" className="btn btn-outline-primary mr-1 mb-1">
							<i className="fa-solid fa-grip" />
						</Link>
						<a href="/ProdutsTablePage" className="btn btn-primary mr-1 mb-1">
							<i className="fa-solid fa-list" />
						</a>
					</div> */}
				</div>

				<div className="table-responsive my-4">
			
                {resGetCategories != null ? (
                    <table className="table table-striped table-bordered dom-jQuery-events">
                    <thead>
                        <tr>
                            <th>
							الاسم
                            </th>
                           
                            <th>
							الإجرائات
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            resGetCategories?.data.data.map((item) =>{
                                return (
                                    <tr>
                            <td>
                                <span>{item.categoryName}</span>
                            </td>
                           
                            <td>
                                <div className="groupOptions">
                                    
								<button
								className="edit-post gold"
								data-toggle="modal"
								data-target="#EditIconForm"
								onClick={() => {setSelectedId(item.id)
									setnewName(item.categoryName)}}
							>
								<span className="edit-tooltip">تعديل</span>
								<span className="edit-icon">
									<i className="ft-edit-2" />
								</span>
							</button>
							
									<ConfirmDeleteComp onDeleteFun={OnDeleteCategory} nameItem={item.categoryName} idItem={item.id}/>
                                </div>
                            </td>
                        </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                ):
                    (<MainLoaderComp/>)
                }
            		
				</div>
				{
					//AddPupUp Modal
				}
				<div
					className="modal fade text-left"
					id="AddIconForm"
					tabindex="-1"
					role="dialog"
					aria-labelledby="myModalLabel34"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="myModalLabel34">
									الاصناف الرئسية
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="ft-x font-medium-2 text-bold-700" />
									</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="">
									<div className="form-group mb-2">
										<label>اسم الصنف</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												// data-validation-required-message="هذا الحقل مطلوب"
												placeholder="اسم الدور"
												value={name}
												onChange={onChangeName}
											/>
										</div>
									</div>
								</div>
							</div>
								<div className="modal-footer">
									<input
										type="reset"
										className="btn bg-light-secondary"
										data-dismiss="modal"
										value="الغاء"
									/>
									{isCreated ? (
										<button onClick={OnSubmit} className="btn btn-primary">
											تأكيد
										</button>
									) : (<BtnLoaderComp/>) 
									}
									
								</div>
						</div>
					</div>
				</div>

				{
					//EditPupUp Modal
				}
				<div
					className="modal fade text-left"
					id="EditIconForm"
					tabindex="-1"
					role="dialog"
					aria-labelledby="myModalLabel34"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="myModalLabel34">
									تعديل التصنيفات الرئسية
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="ft-x font-medium-2 text-bold-700" />
									</span>
								</button>
							</div>
							<form>
								<div className="modal-body">
									<div className="">
										<div className="form-group mb-2">
											<label>تعديل الصنف</label>
											<div className="controls">
												<input
													type="text"
													name="text"
													className="form-control"
													data-validation-required-message="هذا الحقل مطلوب"
													placeholder=""
													value={newName}
													onChange={onChangeNewName}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<input
										type="reset"
										className="btn bg-light-secondary"
										data-dismiss="modal"
										value="الغاء"
									/>{
										isUpadte? (
											<button onClick={changeName} type="submit" className="btn btn-primary">
											تاكيد
										</button>
										):(<BtnLoaderComp/>)
									}

								</div>
							</form>
						</div>
					</div>
				</div>


			</div>
		</div>
        
	);
}
