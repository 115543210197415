import React from 'react';
import NavBar from '../Components/sheardComponent/NavBar';
import SideBar from '../Components/sheardComponent/SideBar';
import { Outlet } from 'react-router-dom';
import Footer from '../Components/sheardComponent/Footer';
import TopBtn from '../Components/sheardComponent/TopBtn';
import SideNotification from '../Components/sheardComponent/SideNotification';
export default function AdminDashboardLayout() {
	return (
		<div className='AdminDashboardLayout'>
			<NavBar />
			<div class="wrapper">
				<SideBar />
				<div class="main-panel">
					<div class="main-content">
						<div class="content-overlay" />
						<div class="content-wrapper">
							<Outlet />
						</div>
					</div>
                    <Footer/>
                    <TopBtn/>
				</div>
			</div>
            <SideNotification/>
			<div class="sidenav-overlay"></div>
		</div>
	);
}
