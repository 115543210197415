import React from 'react'

export default function BtnLoaderComp() {
  return (
    <div className='BtnLoaderContainer'>
    <div className="BtnLoader">
        <div className="loader-small"></div>
        <div className="loader-large"></div>
    </div>
</div>
  )
}
