import axios from "axios";
import { CREATE_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORY, UPDATE_CATEGORY } from "../type";
import { type } from '@testing-library/user-event/dist/type';

//get all Roles 
export const getAllCategories = () => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: 'https://smartmenulocalapi.runasp.net/api/Categories/GetAllCategoriesAsync',
            });   

            dispatch({
                type: GET_ALL_CATEGORY,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: GET_ALL_CATEGORY,
            payload: e.response,
        })
    }   
}

// export const createNewCategory = (data) => async (dispatch) => {
//     try {
//         if(data === null){
//             dispatch({
//                 type: CREATE_CATEGORY,
//                 payload: data,
//             })
//             return;
//         }


//         const res = await axios({
//             method: 'post',
//             url: `https://smartmenulocalapi.runasp.net/api/Categories`,
//             data: data
//             });   

//             dispatch({
//                 type: CREATE_CATEGORY,
//                 payload: res,
//             })
            
//     } catch (e) {
//         dispatch({
//             type: CREATE_CATEGORY,
//             payload: e.response,
//         })
//     }
// }

export const createNewCategory = (data) => async (dispatch) => {
    const payload = data ? await postCategory(data) : data;
    dispatch({ type: CREATE_CATEGORY, payload });
}
const postCategory = async (data) => {
    try {
        const response = await axios.post(`https://smartmenulocalapi.runasp.net/api/Categories`, data);
        return response;
    } catch (e) {
        return e.response;
    }
}

export const updateCategory = (data) => async (dispatch) => {
    const payload = data ? await putUpdateCategory(data) : data;
    dispatch({
        type: UPDATE_CATEGORY,
        payload
    })

}
const putUpdateCategory = async (data) => {
    try{
        const res = await axios({
            method: 'put',
            url: `https://smartmenulocalapi.runasp.net/api/Categories/UpdateCategoryAsync`,
            data: data
        })
        return res;
    }catch (e) {
        return e.response;
    }
}




export const deleteCategory = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'delete',
            url: `https://smartmenulocalapi.runasp.net/api/Categories/DeleteCategoryIdAsync/${data}`,
            });   

            dispatch({
                type: DELETE_CATEGORY,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: DELETE_CATEGORY,
            payload: e.response,
        })
    }


}

