import './App.css';
import HomePage from './Pages/AdminPage/HomePage';
// import NavBar from './Components/sheardComponent/NavBar';
// import SideBar from './Components/sheardComponent/SideBar';
// import Footer from './Components/sheardComponent/Footer';
// import TopBtn from './Components/sheardComponent/TopBtn';
// import SideNotification from './Components/sheardComponent/SideNotification';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ProductsTablePage from './Pages/AdminPage/ProductsTablePage';
import ProductsPage from './Pages/AdminPage/ProductsPage';
import WorkersPage from './Pages/AdminPage/WorkersPage';
import WorkersAccountPage from './Pages/AdminPage/WorkersAccountPage';
import ReviewsLocalPage from './Pages/AdminPage/ReviewsLocalPage';
import AdminDashboardLayout from './Layouts/AdminDashboardLayout';
import AdminLoginLayout from './Layouts/AdminLoginLayout';
import AdminLoginPage from './Pages/AdminPage/AdminLoginPage';
import AdminCategoriesPage from './Pages/AdminPage/AdminCategoriesPage';
import AdminRegisterPage from './Pages/AdminPage/AdminRegisterPage';
import AdminRolesPage from './Pages/AdminPage/AdminRolesPage';
import AdminTablesPage from './Pages/AdminPage/AdminTablesPage';
import AdminBillsPage from './Pages/AdminPage/AdminBillsPage';
import { ToastContainer } from 'react-toastify';
import OrderOnlinPage from './Pages/AdminPage/OrderOnlinPage';

const PrivateRoute = ({ condition, children }) => {
  return condition ? children : <Navigate to="/AdminLoginPage" />;
};

function App() {

  let date1 = new Date(localStorage.getItem("refreshTokenExpiration")?.slice(1, 24) + 'Z'); // First date
  let date2 = new Date(); // Second date (current date and time)

  let token = localStorage.getItem("token")
  let role = localStorage.getItem("Role")
  let differenceInMilliseconds = date2 - date1; // Difference in milliseconds
  const isAuthenticated = token != null && role == "Admin";

  return (

    <div
      className={`vertical-layout vertical-menu 2-columns  navbar-sticky ${localStorage.getItem('DarkMode')}`}
      data-menu="vertical-menu"
      data-col="2-columns"
    >


      <ToastContainer />

      <BrowserRouter>

        <Routes>
          <Route element={<AdminDashboardLayout />}>
            <Route path="/" element={
              <PrivateRoute condition={isAuthenticated}>
                <HomePage />
              </PrivateRoute>
            } />
            <Route path="/ProdutsPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <ProductsPage />
              </PrivateRoute>
            } />
            <Route path="/ProdutsTablePage" element={
              <PrivateRoute condition={isAuthenticated}>
                <ProductsTablePage />
              </PrivateRoute>
            } />
            <Route path="/WorkersPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <WorkersPage />
              </PrivateRoute>
            } />
            <Route path="/WorkersAccountPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <WorkersAccountPage />
              </PrivateRoute>
            } />
            <Route path="/ReviewsLocalPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <ReviewsLocalPage />
              </PrivateRoute>
            } />
            <Route path="/AdminCategoriesPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <AdminCategoriesPage />
              </PrivateRoute>
            } />
            <Route path="/AdminRolesPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <AdminRolesPage />
              </PrivateRoute>
            } />
            <Route path="/AdminTablesPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <AdminTablesPage />
              </PrivateRoute>
            } />
            <Route path="/AdminBillsPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <AdminBillsPage />
              </PrivateRoute>
            } />
            <Route path="/OrderOnlinPage" element={
              <PrivateRoute condition={isAuthenticated}>
                <OrderOnlinPage />
              </PrivateRoute>
            } />
          </Route>


          <Route element={<AdminLoginLayout />}>
            <Route path="/AdminLoginPage" element={<AdminLoginPage />} />
            <Route path="/AdminRegisterPage" element={<AdminRegisterPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


/* 
<NavBar/>
      
      <div className="wrapper">
      <BrowserRouter>
        <SideBar/>

        <div className="main-panel">
        
        <div className="main-content">
          <div className="content-overlay"></div>
          <div className="content-wrapper">

          
            <Routes>
              <Route path='/' element={<HomePage/>}/>
              <Route path='/ProdutsPage' element={<ProductsPage/>}/>
              <Route path='/ProdutsTablePage' element={<ProductsTablePage/>}/>
              <Route path='/WorkersPage' element={<WorkersPage/>}/>
              <Route path='/WorkersAccountPage' element={<WorkersAccountPage/>}/>
              <Route path='/ReviewsLocalPage' element={<ReviewsLocalPage/>}/>
            </Routes>
          
          

          </div>
        </div>
          
          <Footer/>
          <TopBtn/>

        </div>
        </BrowserRouter>
      </div>

      <SideNotification/>

*/
