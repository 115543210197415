import notify from './../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import React from 'react'
import { createNewFood, deletFood, getAllFoods } from '../../redux/actions/foodAction';
import { getAllCategories } from '../../redux/actions/categoryAction';

const FoodHook = () => {
    const [loading, setloading] = useState(false);
    const [isDeleted, setisDeleted] = useState(false);
    const [isCreated, setIsCreated] = useState(true);
    // const [isChangeState, setisChangeState] = useState(false);
    // const [isUpdate, setisUpdate] = useState(false);

    const resGetFoods = useSelector(state => state.foodReduser.getAllFoods);
    const resGetCategories = useSelector(state => state.categoryReducer.getAllCategories);
    const resCreateFood = useSelector(state => state.foodReduser.createNewFood);
    const resDeleteFood = useSelector(state => state.foodReduser.deleteFood);

    const dispatch = useDispatch();

    const [userId, setuserId] = useState('')
    const [name, setname] = useState('')
    const [description, setdescription] = useState('')
    const [price, setprice] = useState('')
    const [isSpicy, setisSpicy] = useState(false)
    const [categoryId, setcategoryId] = useState('')
    const [category, setcategory] = useState('')
    const [deleted, setdeleted] = useState(false)
    const [imagesName, setimagesName] = useState('')
    const [selectedFile, setselectedFile] = useState('')

    const onSelectUserId = (value) => {
        setuserId(value)
    }

    const onChangeName = (e) => {
        setname(e.target.value)
    }

    const onChangedescription = (e) => {
        setdescription(e.target.value)
    }

    const onChangeprice = (e) => {
        setprice(e.target.value)
    }

    const onChangeisSpicy = (e) => {
        setisSpicy(e.target.value)
    }

    const onChangecategoryId = (e) => {
        setcategoryId(e.target.value)
        console.log(e.target.value)
    }

    const onChangedeleted = (e) => {
        setdeleted(e.target.value)
    }

    // وقت ما يخنار صوره المستخدم
    const onImageChange = (event) =>{
        // تتاكد انه ختار صورة المستخدم الاثنين نفس بعض بس الثاني ل مثلا ختار عدة صور يشل الاول اي اللي اندكسها صفر
        if(event.target.files && event.target.files[0]){
            // تحويل الامتداد لصورة
            setimagesName(URL.createObjectURL(event.target.files[0]));
            // حفظ الامتداد
            setselectedFile(event.target.files[0]);
        }
    }

    const isValidationValuesCorrect = () => {
        if (name === "" && description === "" && price === "" && categoryId === "" && selectedFile === "" ) {
            notify("الرجاء تعبئة جميع الحقول", "error")
            return false;
        }
        return true;
    }



    ////////////////////////////////
    useEffect( () => {
        if(loading === false){
            dispatch(getAllFoods());
            dispatch(getAllCategories());
            console.log(resGetFoods + " resgetFoods")
        }
    }, [loading])

    ////////////////////////////////
    const OnSubmit = async () =>{
        // event.preventDefault();
        console.log("onSubmit")
        if (! isValidationValuesCorrect()){
            return;
        }

        setIsCreated(false);
        // طريقة لحفظ الصور في ال ا بي اي شرحها في مجلد 10 حلقة 7
        const formData = new FormData();
        formData.append('Name',name)
        formData.append('Description',description)
        formData.append('Price',price)
        formData.append('IsSpicy',isSpicy)
        formData.append('CategoryId',categoryId)
        formData.append('Deleted',isDeleted)
        formData.append('Images',selectedFile)

        // عشان تبين انه المستخدم ضغط و الحدث برضه جالس يحمل
        // setloading(true)

        // console.log(formData)
        // console.log(name)
        // console.log(description)
        // console.log(price)
        // console.log(isSpicy)
        // console.log(categoryId)
        // console.log(isDeleted)
        // console.log(selectedFile)
        // استدعاء اكشن الكرييت
        await dispatch(createNewFood(formData))

        // خلاص الحين استخدمنا الدباتش و احتفظت بقا نقول ان التحميل انتها ونغيره لفولس
        setloading(true)
        setIsCreated(true);
        console.log(resCreateFood +" cccc")
    }
    useEffect(() => {
        if(isCreated === true){
            console.log(isCreated+" isCreated")
            console.log(resCreateFood +" ddddddddd")
            if(resCreateFood){
                console.log(resCreateFood+" اا")
                console.log(resCreateFood+" deleted")
                if(resCreateFood.data.success === true){
                    notify(resCreateFood.data.message, "success");
                }
                else{
                    notify(resCreateFood.data.message, "error");
                }    
            }
            // setIsCreated(true);
        }
        setloading(false);
    }, [isCreated]);
    
    ////////////////////////////////
    const OnDeleteFood = async (foodId) =>{
        // console.log(foodId+" deleted")
        await dispatch(deletFood(foodId));
        setloading(true);
        setisDeleted(true);
    }
    useEffect(() => {
        if(isDeleted === true){
            // console.log(isDeleted+" deleted")
            // console.log(resDeleteFood+" اا")
            if(resDeleteFood){
                // console.log(resDeleteFood+" deleted")
                if(resDeleteFood.data.success === true){
                    notify(resDeleteFood.data.message, "success");
                }
                else{
                    notify(resDeleteFood.data.message, "error");
                }    
            }
            setloading(false);
            setisDeleted(false);
        }
    }, [isDeleted]);

    return[isCreated, name, description, price, isSpicy, deleted, onChangeName, onChangedescription, onChangeprice, onChangeisSpicy, onChangecategoryId, onChangedeleted, onImageChange, resGetFoods, resCreateFood, resGetCategories, OnSubmit, OnDeleteFood]
}

export default FoodHook
