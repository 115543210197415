import React from 'react'

export default function MainLoaderComp() {
  return (
    <div className='mainLoaderContainer'>
    <div className="mainLoader">
        <div className="loader-small"></div>
        <div className="loader-large"></div>
    </div>
</div>
  )
}
