import React from 'react';

export default function DateTableWithImg() {
	return (
		<div className="table-responsive my-4">
			<table className="table table-striped table-bordered dom-jQuery-events">
				<thead>
					<tr>
						<th>
							I<span>الصورة</span>
						</th>
						<th>
							N<span>الاسم</span>
						</th>
						<th>
							O<span>الوصف</span>
						</th>
						<th>
							P<span>الحالة</span>
						</th>
						<th>
							A<span>العمر</span>
						</th>
						<th>
							S<span>تاريخ التوظيف</span>
						</th>
						<th>
							Sa<span>الإجرائات</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="tableContainerImg " style={{}}>
								<img
									className="w-100 h-100"
									src="../../app-assets/img/photos/08.jpg"
									alt="food img"
								/>
							</div>
						</td>
						<td><span>برست</span></td>
						<td><span>صحن برست مع قطعتين دجاج</span></td>
						<td>
                        {
                            true ? (
                                <i className="fa-regular fa-circle-check statusData statusActive"></i>
                        ) : (
                            
                            <i className="fa-regular fa-circle-xmark statusData statusNActive"></i>
                        )}
                        </td>
						<td><span>47</span></td>
						<td><span>2011/03/21</span></td>
						<td>
							<div className="groupOptions">
								<div className="edit-post success confirm-text">
									<span className="edit-tooltip ">تغيير الحالة</span>
									<span className="edit-icon"><i className="fa-solid fa-circle-check"></i></span>
								</div>
								<button className="edit-post gold" data-toggle="modal" data-target="#iconForm">
									<span className="edit-tooltip">تعديل</span>
									<span className="edit-icon">
										<i className="ft-edit-2" />
									</span>
								</button>
								<button className="edit-post red confirm-text">
									<span className="edit-tooltip">حذف</span>
									<span className="edit-icon">
										<i className="ft-trash" />
									</span>
								</button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div
								className="tableContainerImg "
								style={{ width: '120px !important;', height: '75px !important;' }}
							>
								<img
									className="w-100 h-100"
									src="../../app-assets/img/photos/08.jpg"
									alt="food img"
								/>
							</div>
						</td>
						<td>Lael Greer</td>
						<td>Systems Administrator</td>
						<td>                        {
                            false ? (
                                <i className="fa-regular fa-circle-check statusData statusActive"></i>
                        ) : (
                            
                            <i className="fa-regular fa-circle-xmark statusData statusNActive"></i>
                        )}</td>
						<td>21</td>
						<td>2009/02/27</td>
						<td><div className="groupOptions">
                        <button className="edit-post success ChangeStatusAlert">
                            <span className="edit-tooltip ">تغيير الحالة</span>
                            <span className="edit-icon"><i className="fa-solid fa-circle-check"></i></span>
                        </button>
                        <button  className="edit-post gold" data-toggle="modal" data-target="#iconForm">
                            <span className="edit-tooltip">تعديل</span>
                            <span className="edit-icon">
                                <i className="ft-edit-2" />
                            </span>
                        </button>
                        <button className="edit-post red DeleteAlert">
                            <span className="edit-tooltip">حذف</span>
                            <span className="edit-icon">
                                <i className="ft-trash" />
                            </span>
                        </button>
                    </div></td>
					</tr>
					<tr>
						<td>
							<div
								className="tableContainerImg "
								style={{ width: '120px !important;', height: '75px !important;' }}
							>
								<img
									className="w-100 h-100"
									src="../../app-assets/img/photos/08.jpg"
									alt="food img"
								/>
							</div>
						</td>
						<td>Jonas Alexander</td>
						<td>Developer</td>
						<td>San Francisco</td>
						<td>30</td>
						<td>2010/07/14</td>
						<td>$86,500</td>
					</tr>
					<tr>
						<td>
							<div
								className="tableContainerImg "
								style={{ width: '120px !important;', height: '75px !important;' }}
							>
								<img
									className="w-100 h-100"
									src="../../app-assets/img/photos/08.jpg"
									alt="food img"
								/>
							</div>
						</td>
						<td>Shad Decker</td>
						<td>Regional Director</td>
						<td>Edinburgh</td>
						<td>51</td>
						<td>2008/11/13</td>
						<td>$183,000</td>
					</tr>
					<tr>
						<td>
							<div
								className="tableContainerImg "
								style={{ width: '120px !important;', height: '75px !important;' }}
							>
								<img
									className="w-100 h-100"
									src="../../app-assets/img/photos/08.jpg"
									alt="food img"
								/>
							</div>
						</td>
						<td>Michael Bruce</td>
						<td>Javascript Developer</td>
						<td>Singapore</td>
						<td>29</td>
						<td>2011/06/27</td>
						<td>$183,000</td>
					</tr>
					<tr>
						<td className="d-flex justify-content-center">
							<div
								className="tableContainerImg "
								style={{ width: '120px !important;', height: '75px !important;' }}
							>
								<img
									className="w-100 h-100"
									src="../../app-assets/img/photos/08.jpg"
									alt="food img"
								/>
							</div>
						</td>
						<td>Donna Snider</td>
						<td>Customer Support</td>
						<td>New York</td>
						<td>27</td>
						<td>2011/01/25</td>
						<td>$112,000</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
