import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import RegisterHook from '../../hook/auth/register-hook';
import { ToastContainer } from 'react-toastify';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import $ from 'jquery';

export default function AdminRegisterPage() {
	const [
		isProgressOn,
		name,
		phone,
		password,
		confirmPassword,
		onChangeName,
		onChangePhone,
		onChangePassword,
		onChangeConfirmPassword,
		OnSubmit,
		resRoles,
		selectedRole, handleSelectRoleChange
	] = RegisterHook();

	useEffect(() => {
		$(document).ready(function() {
			if ($('#login').length > 0) {
			  $('.main-content').css('margin', '0px');
			}
		  });
		  
	}, [])
	return (
		<section id="login" className="auth-height">
			<div className="row full-height-vh m-0">
				<div className="col-12 d-flex align-items-center justify-content-center">
					<div className="card overflow-hidden">
						<div className="card-content">
							<div className="card-body auth-img">
								<div className="row m-0">
									<div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
										<img
										src="../../../main-assets/imgs/mainLogoDark.jpg"
										alt=""
										class="img-fluid"
										width="300"
										height="230"
										style={{borderRadius: ".35rem"}}
										/>
									</div>
									<div className="col-lg-6 col-12 px-4 py-3">
										<h4 className="mb-2 card-title">انشاء حساب</h4>
										<p>اهلا بك, انشاء حسابك لإدارة مطعمك.</p>

										<fieldset className="form-group mb-1">
											<label>الاسم الكامل</label>
											<input
												type="text"
												className="form-control"
												placeholder="الاسم"
												value={name}
												onChange={onChangeName}
											/>
										</fieldset>
										<fieldset className="form-group mb-1">
											<label>الرقم</label>
											<input
												type="number"
												className="form-control "
												placeholder="رقم الهاتف"
												value={phone}
												onChange={onChangePhone}
											/>
										</fieldset>
										<fieldset className="form-group mb-1">
											<label>كلمة المرور</label>

											<input
												type="password"
												className="form-control "
												placeholder="الرمز"
												value={password}
												onChange={onChangePassword}
											/>
										</fieldset>
										<fieldset className="form-group mb-1">
											<label>تاكيد كلمة المرور</label>
											<input
												type="password"
												className="form-control "
												placeholder="تاكيد الرمز"
												value={confirmPassword}
												onChange={onChangeConfirmPassword}
											/>
										</fieldset>
										
										{
											
											resRoles?.data != null ? (
												<fieldset className="form-group mb-1">
													<label for="basicSelect">الصلاحية</label>
													<select value={selectedRole} onChange={handleSelectRoleChange} className="form-control" id="basicSelect">
														<option disabled selected>
															اختر صلاحية
														</option>
														{
															resRoles?.data.data.map((item) =>{
																return (<option key={item.id} >{item.roleName}</option>)
															})
														}
													</select>
												</fieldset>
											):(
												<BtnLoaderComp/>
											)
										}

										
										{/* <div className="d-sm-flex justify-content-between mb-3 font-small-2">
											<div className="remember-me mb-2 mb-sm-0">
												<div className="checkbox auth-checkbox">
													<input type="checkbox" id="auth-ligin" />
													<label for="auth-ligin">
														<span>Remember Me</span>
													</label>
												</div>
											</div>
											<a href="auth-forgot-password.html">Forgot Password?</a>
										</div> */}
										<div className="d-flex justify-content-between flex-sm-row flex-column">

											{isProgressOn ? (
												<BtnLoaderComp/>
											) : (
												<button onClick={OnSubmit} className="btn btn-primary w-100 mt-2">
													انشاء حساب
												</button>
											)}
										</div>
										<hr />

									<h6 className="text-primary text-center mb-2">او</h6>
									<Link
									to={'/WorkersAccountPage'}
									className="btn bg-light-primary mb-2 mb-sm-0 m-auto d-block w-100	"
								>
									العودة
								</Link>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
