import axios from "axios";
import {GET_ALL_BILLS } from "../type";

//get all Roles 
export const getAllBills = () => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: 'https://smartmenulocalapi.runasp.net/api/Bills/Get-AllBillWithRelatedData-Async',
            });   

            dispatch({
                type: GET_ALL_BILLS,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: GET_ALL_BILLS,
            payload: e.response,
        })
    }   
}

