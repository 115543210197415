import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import RoleHook from '../../hook/roles/role-hook';

import { ConfirmDeleteComp } from '../../Components/sheardComponent/ConfirmDeleteComp';
import { FixUpdateDataTableHook } from '../../Components/sheardComponent/FixUpdateDataTableHook';
import { ChangStateComp } from '../../Components/sheardComponent/ChangStateComp';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import MainLoaderComp from '../../Components/sheardComponent/MainLoaderComp';


export default function AdminRolesPage() {


	const [ name, onChangeName, resGetRoles, OnSubmit, isCreated, OnDeleteRole ] = RoleHook();
	
	// تعديه اول شي المتغير لي فيه البيانات حق الداتا تيبل و ثاني برمتر سلكتور الجدول نفسه
	FixUpdateDataTableHook(resGetRoles, '.dom-jQuery-events');

	return (
		<div>
			<div className="productsViewSection">
				<div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
					<button
						className="main-btn"
						data-toggle="modal"
						data-target="#AddIconForm"
						style={{ padding: '7px 35px', fontSize: '18px', fontWeight: 'bold' }}
					>
						<span>اضافة دور</span>
						<div className="svg-wrapper-1">
							<div className="svg-wrapper">
								<i className="fa-solid fa-circle-plus" />
							</div>
						</div>
					</button>

					{/* <div className="btns">
						<Link to="/ProdutsPage" className="btn btn-outline-primary mr-1 mb-1">
							<i className="fa-solid fa-grip" />
						</Link>
						<a href="/ProdutsTablePage" className="btn btn-primary mr-1 mb-1">
							<i className="fa-solid fa-list" />
						</a>
					</div> */}
				</div>

				<div className="table-responsive my-4">
			
                {resGetRoles != null ? (
                    <table className="table table-striped table-bordered dom-jQuery-events">
                    <thead>
                        <tr>
                            <th>
                                الاسم
                            </th>
                            <th>
                                الإجرائات
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            resGetRoles?.data.data.map((item,index) =>{
                                return (
                            <tr>
                            <td>
                                <span>{item.roleName}</span>
                            </td>

                            <td>
                                <div className="groupOptions">
{
// 	<button
// 	className="edit-post gold"
// 	data-toggle="modal"
// 	data-target="#EditIconForm"
// >
// 	<span className="edit-tooltip">تعديل</span>
// 	<span className="edit-icon">
// 		<i className="ft-edit-2" />
// 	</span>
// </button>
}
									<ConfirmDeleteComp onDeleteFun={OnDeleteRole} nameItem={item.roleName} idItem={item.roleName}/>
                                </div>
                            </td>
                        </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                ):
                    (
						<MainLoaderComp/>
					)
                }
            		
				</div>

				{
					//PupUp Modal
				}
				<div
					className="modal fade text-left"
					id="AddIconForm"
					tabindex="-1"
					role="dialog"
					aria-labelledby="myModalLabel34"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="myModalLabel34">
									الأدوار الرئسية
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="ft-x font-medium-2 text-bold-700" />
									</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="">
									<div className="form-group mb-2">
										<label>الدور</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												data-validation-required-message="هذا الحقل مطلوب"
												placeholder="اسم الدور"
												value={name}
												onChange={onChangeName}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<input
									type="reset"
									className="btn bg-light-secondary"
									data-dismiss="modal"
									value="الغاء"
								/>
								{isCreated ? (
									<button onClick={OnSubmit} className="btn btn-primary">
										تأكيد
									</button>
								) : (
									<BtnLoaderComp/>
								)}
							</div>
						</div>
					</div>
				</div>

				{
					//ُEditPupUp Modal
				}
				<div
					className="modal fade text-left"
					id="EditIconForm"
					tabindex="-1"
					role="dialog"
					aria-labelledby="myModalLabel34"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="myModalLabel34">
									الأدوار الرئسية
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="ft-x font-medium-2 text-bold-700" />
									</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="">
									<div className="form-group mb-2">
										<label>تعديل الدور</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												// data-validation-required-message="هذا الحقل مطلوب"
												placeholder="اسم الدور"
												value={name}
												onChange={onChangeName}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<input
									type="reset"
									className="btn bg-light-secondary"
									data-dismiss="modal"
									value="الغاء"
								/>
								{isCreated ? (
									<button onClick={OnSubmit} className="btn btn-primary">
										تأكيد
									</button>
								) : (
									<BtnLoaderComp/>
								)}
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	);
}

{
// 	resGetRoles != null ? (
// 	<table className="table table-striped table-bordered dom-jQuery-events">
// 	<thead>
// 		<tr>
// 			<th>
// 				الاسم
// 			</th>
// 			<th>
// 				الحالة
// 			</th>
// 			<th>
// 				الإجرائات
// 			</th>
// 		</tr>
// 	</thead>
// 	<tbody>
// 		{
// 			resGetRoles?.data.data.map((item) =>{
// 				return (
// 			<tr>
// 			<td>
// 				<span>{item.roleName}</span>
// 			</td>
// 			<td>
// 				{true ? (
// 					<i className="fa-regular fa-circle-check statusData statusActive" />
// 				) : (
// 					<i className="fa-regular fa-circle-xmark statusData statusNActive" />
// 				)}
// 			</td>
// 			<td>
// 				<div className="groupOptions">
// 					<div className="edit-post success confirm-text DeleteAlert">
// 						<span className="edit-tooltip ">تغيير الحالة</span>
// 						<span className="edit-icon">
// 							<i className="fa-solid fa-circle-check" />
// 						</span>
// 					</div>
// 					<button
// 						className="edit-post gold"
// 						data-toggle="modal"
// 						data-target="#EditIconForm"
// 					>
// 						<span className="edit-tooltip">تعديل</span>
// 						<span className="edit-icon">
// 							<i className="ft-edit-2" />
// 						</span>
// 					</button>
// 					<ConfirmDeleteComp onDeleteFun={OnDeleteRole} nameItem={item.roleName}/>
// 				</div>
// 			</td>
// 		</tr>
// 				)
// 			})
// 		}
// 	</tbody>
// </table>
// ):
// 	(<b>Loading</b>)
}