// ConfirmDelete.js
import React from 'react';
import Swal from 'sweetalert2';

export function ChangeFreezeCom({ onChangeStateFun, nameItem, idItem }) {
    const ChangState = () => {
        Swal.fire({
            title: `تغيير حالة تجميد ( ${nameItem} ) ؟`,
            text: "هل انت متاكد من تغيير حالة العنصر",
            icon: "warning",
            iconColor: 'var(--info)',
            showCancelButton: true,
            confirmButtonColor: 'var(--info)',
            // cancelButtonColor: 'var(--red)',
            confirmButtonText: 'متاكد',
            cancelButtonText: 'إلغاء',
                // تغيير كلاسات الأزرار
            customclassName: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn bg-light-secondary ml-1'
            }
        }).then(function (result) {
            if (result.value) {
                onChangeStateFun(idItem);
                
            }
        });
    };

    return (
        <button className="edit-post info confirm-text" onClick={ChangState}>
        <span className="edit-tooltip ">تجميد العنصر</span>
        <span className="edit-icon">
            <i className="icon-active fa-solid fa-snowflake"></i>
        </span>
    </button>
    );
}