import React from 'react';
import { Outlet } from 'react-router-dom';

export default function AdminLoginLayout() {
	return (
		<div>
			<div class="wrapper">
				<div class="main-panel">
					<div class="main-content">
						<div class="content-overlay" />
						<div class="content-wrapper">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
