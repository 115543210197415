import axios from "axios";
import { CREATE_NEW_ROLE, DELETE_ROLE, GET_ALL_ROLES } from "../type";

//get all Roles 
export const getAllRoles = () => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: 'https://smartmenulocalapi.runasp.net/api/Roles/GetAllRolesAsync',
            });   

            dispatch({
                type: GET_ALL_ROLES,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: GET_ALL_ROLES,
            payload: e.response,
        })
    }   
}

export const createNewRole = (data) => async (dispatch) => {
    try {
        if(data === null){
            dispatch({
                type: CREATE_NEW_ROLE,
                payload: data,
            })
            return;
        }

        const res = await axios({
            method: 'post',
            url: `https://smartmenulocalapi.runasp.net/api/Roles/AddRoleAsync?roleName=${data}`,
            });   

            dispatch({
                type: CREATE_NEW_ROLE,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: CREATE_NEW_ROLE,
            payload: e.response,
        })
    }


}




export const deleteRole = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'delete',
            url: `https://smartmenulocalapi.runasp.net/api/Roles/DeleteRoleAsync/${data}`,
            });   

            dispatch({
                type: DELETE_ROLE,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: DELETE_ROLE,
            payload: e.response,
        })
    }


}

