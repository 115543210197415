import { useEffect } from 'react';
import $ from 'jquery';
import 'datatables.net';

// حل مشكلة انه بعد تمرير البيانات في الجدول تختفي تنسيقات الداتاتيبل و بعد خذف عنصر من جدول
// تعديه اول شي المتغير لي فيه البيانات حق الداتا تيبل و ثاني برمتر سلكتور الجدول نفسه
export const FixUpdateDataTableHook = (data, selector) => {
  useEffect(() => {
    if (data != null) {

      $(selector).DataTable({
        retrieve: true,
        dom: "<'row'<'col-sm-6 col-12 searchTable'f><'col-sm-6 col-12 d-flex align-items-center justify-content-end'B>>" +
             "<'row'<'col-md-12'tr>>" +
             "<'row'<'col-sm-4 col-12'l><'col-sm-4 col-12'p><'col-sm-4 col-12 d-flex align-items-center justify-content-end'i>>",
        buttons: [
            'copy', 'csv', 'excel', 'pdf', 'print'
        ],
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
        language: {
            lengthMenu: "عرض _MENU_ عناصر",
            info: "عرض _START_ إلى _END_ من أصل _TOTAL_ عنصر",
            infoEmpty: "عرض 0 إلى 0 من أصل 0 عنصر",
            infoFiltered: "(تمت تصفية _MAX_ من العناصر)",
            paginate: {
                first: "الأول",
                last: "الأخير",
                next: "التالي",
                previous: "السابق"
            },
            search: `                
            <button
            class="btn btn-primary round position-absolute"
            type="button"
            
        >
            
        </button>`
        }
    });

      $(selector).DataTable().draw();
    }
  }, [data, selector]);
};
