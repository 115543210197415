import React, { useState } from 'react';

export default function OrderOnlinCard() {
    const [borderClass, setBorderClass] = useState('');  
    const changeStatus = (event) => {
      const selectedValue = event.target.value;
  
      let newBorderClass, newTextClass;
  
      switch (selectedValue) {
          case "received":
            newBorderClass = "";
            break;
        case "pending":
          newBorderClass = "gold";
          break;
        case "delivering":
          newBorderClass = "gold";
          break;
        case "Donedelivery":
          newBorderClass = "green";
          break;
        case "rejected":
          newBorderClass = "red";
          break;
        default:
          newBorderClass = "";
          newTextClass = "";
      }
  
      setBorderClass(newBorderClass);
    };


	return (
		<div className="card OrderCard">
			<div className="card-header">
				<h4 className="card-title">
					طلب <span>#1</span>
				</h4>
				<p className="m-0">
					
					2000/12/30 <b>10:10pm</b>
				</p>
			</div>
			<div className="card-content">
				<div className="card-img">
					<div className="foodRowCard mt-0">
						<div className="avatar avatar-md mr-1">
							<img
								className="img-fluid"
								src="../../../app-assets/img/photos/07.jpg"
								alt="Card image cap"
							/>
						</div>
						<div className="infoFood">
							<h5>طبق شاورما دجاج</h5>
							<p className="m-0">×2</p>
						</div>
						<div className="PriceFood">
							2500<span>ر.ي</span>
						</div>
					</div>
					<div className="foodRowCard">
						<div className="avatar avatar-md mr-1">
							<img
								className="img-fluid"
								src="../../../app-assets/img/photos/07.jpg"
								alt="Card image cap"
							/>
						</div>
						<div className="infoFood">
							<h5>طبق شاورما دجاج</h5>
							<p className="m-0">×2</p>
						</div>
						<div className="PriceFood">
							2500<span>ر.ي</span>
						</div>
					</div>
					<div className="foodRowCard">
						<div className="avatar avatar-md mr-1">
							<img
								className="img-fluid"
								src="../../../app-assets/img/photos/07.jpg"
								alt="Card image cap"
							/>
						</div>
						<div className="infoFood">
							<h5>طبق شاورما دجاج</h5>
							<p className="m-0">×2</p>
						</div>
						<div className="PriceFood">
							2500<span>ر.ي</span>
						</div>
					</div>
					<button
						type="button"
						className="btn btn-outline-primary btn-icon btn-floating halfway-fab round mr-1 mb-1"
					>
						<i className="fa fa-info" />
					</button>
				</div>
				<div className="card-body">
					<p className="card-text m-0">مرعي بن هلابي</p>
					<p className="card-text m-0 d-flex justify-content-sm-between">
						<span>779891026</span>
						<span>المكلاء-روكب</span>
					</p>
				</div>
			</div>
			<div className="card-footer text-muted pt-0">
				<div className="row justify-content-between align-items-center w-100 m-0">
					<div className="col-7 p-0">
						
						<select className={`form-control MultiColorSelect p-1 ${borderClass}`} id="statusSelect" onChange={changeStatus}>
                        <option value="received">تم استلام الطلب</option>
                        <option value="pending">قيد التجهيز</option>
                        <option value="delivering">قيد التوصيل</option>
                        <option value="Donedelivery">تم التوصيل</option>
                        <option value="rejected">رفض الطلب</option>
						</select>
					</div>
					<div className="col-4 p-0 text-right">
						<div className="">
							<span className='NumItem'>
								×6 عناصر
							</span>
							<h4 className='TotlePrice align-items-center d-flex justify-content-end m-0'>
								7500 <span>ر.س</span>
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
