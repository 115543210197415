
import notify from './../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { createTable, deleteTable, getAllTables, updateTable } from '../../redux/actions/tableAction';

const TablesHook = () => {

    const [loading, setLoading] = useState(false);
    const [isCreated, setIsCreated] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isDeleted, setisDeleted] = useState(false);

    const dispatch = useDispatch();

    const resGetTables = useSelector(state => state.tableReducer.getAllTables);
    const resAddTables = useSelector(state => state.tableReducer.createTable);
    const resUpdateTables = useSelector(state => state.tableReducer.updateTable);
    const resDeleteTables = useSelector(state => state.tableReducer.deleteTable);

    const [tableNum, setTableNum] = useState('');
    const [tableCapacity, setTableCapacity] = useState('');
    const [seletedId, setSeletedId] = useState('');


    const onSetTableNum = (e) => {
        setTableNum(e.target.value)
    }
    const onSetTableCapacity = (e) => {
        setTableCapacity(e.target.value)
    }
    const onSetseletedId = (tableId) => {
        setSeletedId(tableId)
    }
    
    const onEditTableNum = (value) => {
        setTableNum(value)
    }
    const onEditTableCapacity = (value) => {
        setTableCapacity(value)
    }


    const isValidationValuesCorrect = () => {
        if (tableNum === "" && tableCapacity === "") {
            notify("جميع الحقول مطلوبة", "error")
            return false;
        }
        return true;
    }


    useEffect(()=>{
        if(loading === false){
            dispatch(getAllTables());       
        }
    }, [loading])

    //create table
    const OnSubmit = async () => {
        if (! isValidationValuesCorrect()){
          return;
        }
        setIsCreated(false);
        
        await dispatch(createTable({
            tableNumber: tableNum,
            capacity: tableCapacity
        }));
        dispatch(getAllTables())
        setLoading(true);
    }
    useEffect(() => { 

        if(resAddTables && resAddTables !== null){

            const {success,message} = resAddTables.date;
            console.log(success + message)
            
            notify(message,success? 'success' : 'error');
        }
        
        setIsCreated(true);
        setLoading(false);
    }, [isCreated])

    // update the table
    const OnUpdate = async (tableId) => {
        
        if (tableNum && tableCapacity){
            await dispatch(updateTable({
                id: tableId,
                tableNumber : tableNum,
                capacity: tableCapacity
            }))
            dispatch(getAllTables());
            setLoading(true);
            setIsUpdated(true);
        }
        else{
            notify("جميع الحقول مطلوبة", "error")
        }
    }
    useEffect(() => {
        if(isUpdated && resUpdateTables){
            console.log(resUpdateTables)
            if(resUpdateTables.data.success === true){
                notify(resUpdateTables.data.message, "success");
            }
            else{
                notify(resUpdateTables.data.message, "error");
            }
        }
        setIsUpdated(false);
        setLoading(false);
    }, [isUpdated])

////////////////////////////////
const OnDelete = async(idTable)=>{
    if(idTable){
        await dispatch(deleteTable(idTable))
        setLoading(true);
        setisDeleted(true)
        dispatch(getAllTables());
    }
}
useEffect(() => {
    if(isDeleted && resDeleteTables){
        if(resDeleteTables.data.success === true){
            notify(resDeleteTables.data.message, "success");
        }
        else{
            notify(resDeleteTables.data.message, "error");
        }    
        setLoading(false);
        setisDeleted(false);
    }
}, [isDeleted])


    return [tableNum, tableCapacity,seletedId, onSetTableNum, onSetTableCapacity, onSetseletedId,onEditTableNum, onEditTableCapacity, resGetTables, OnSubmit, OnUpdate, OnDelete, isCreated, isUpdated]
}

export default TablesHook