import React from 'react'

const SideNotification = () => {
  return (
    <aside className="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar"><a className="notification-sidebar-close"><i className="ft-x font-medium-3 grey darken-1"></i></a>
    <div className="side-nav notification-sidebar-content">
        <div className="row">
            <div className="col-12 notification-nav-tabs">
                <ul className="nav nav-tabs">
                    <li className="nav-item"><a className="nav-link active" id="base-tab1" data-toggle="tab" aria-controls="activity-tab" href="#activity-tab" aria-expanded="true">الاشعارات</a></li>
{                    // <li className="nav-item"><a className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="settings-tab" href="#settings-tab" aria-expanded="false">Settings</a></li>
}                </ul>
            </div>
            <div className="col-12 notification-tab-content">
                <div className="tab-content">
                    <div className="row tab-pane active" id="activity-tab" role="tabpanel" aria-expanded="true" aria-labelledby="base-tab1">
                        <div className="col-12" id="activity">
                            <h5 className="my-2 text-bold-500">الطلبات الجديده</h5>
                            <div className="timeline-left timeline-wrapper mb-3" id="timeline-1">
                                <ul className="timeline">
                                    <li className="timeline-line mt-4"></li>
                                    <li className="timeline-item">
                                        <div className="timeline-badge"><span className="bg-primary bg-lighten-4" data-toggle="tooltip" data-placement="right" title="Portfolio project work"><img className="avatar w-100 h-100" src="../../../app-assets/img/photos/07.jpg" alt="avatar" width="40"/></span></div>
                                        <div className="activity-list-text">
                                            <h6 className="mb-1"><span>طلب #1</span><span className="float-right grey font-italic font-small-2">منذ 2د<br/> 2000$</span></h6>
                                            <p className="mt-0 mb-2 font-small-3">طبق شاورما x2</p>
                                            <p className="mt-0 mb-2 font-small-3">طبق شاورما x2</p>
                                            <a className="notification-note btn btn-sm btn-primary">
                                                <div className="p-1 pl-2"><span className="text-bold-500">التفاصيل</span></div>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="timeline-item">
                                        <div className="timeline-badge"><span className="bg-primary bg-lighten-4" data-toggle="tooltip" data-placement="right" title="Portfolio project work"><img className="avatar w-100 h-100" src="../../../app-assets/img/photos/07.jpg" alt="avatar" width="40"/></span></div>
                                        <div className="activity-list-text">
                                            <h6 className="mb-1"><span>طلب #2</span><span className="float-right grey font-italic font-small-2">منذ 3د<br/> 2000$</span></h6>
                                            <p className="mt-0 mb-2 font-small-3">طبق شاورما x2</p>
                                            <p className="mt-0 mb-2 font-small-3">طبق شاورما x2</p>
                                            <a className="notification-note btn btn-sm btn-primary">
                                                <div className="p-1 pl-2"><span className="text-bold-500">التفاصيل</span></div>
                                            </a>
                                        </div>
                                    </li>


                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="row tab-pane" id="settings-tab" aria-labelledby="base-tab2">
                        <div className="col-12" id="settings">
                            <h5 className="mt-2 mb-3">General Settings</h5>
                            <ul className="list-unstyled mb-0 mx-2">
                                <li className="mb-3">
                                    <div className="mb-1"><span className="text-bold-500">Notifications</span>
                                        <div className="float-right">
                                            <div className="custom-switch">
                                                <input className="custom-control-input" id="noti-s-switch-1" type="checkbox"/>
                                                <label className="custom-control-label" for="noti-s-switch-1"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-small-3 m-0">Use switches when looking for yes or no answers.</p>
                                </li>
                                <li className="mb-3">
                                    <div className="mb-1"><span className="text-bold-500">Show recent activity</span>
                                        <div className="float-right">
                                            <div className="checkbox">
                                                <input id="noti-s-checkbox-1" type="checkbox" checked/>
                                                <label for="noti-s-checkbox-1"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-small-3 m-0">The "for" attribute is necessary to bind checkbox with the input.</p>
                                </li>
                                <li className="mb-3">
                                    <div className="mb-1"><span className="text-bold-500">Product Update</span>
                                        <div className="float-right">
                                            <div className="custom-switch">
                                                <input className="custom-control-input" id="noti-s-switch-4" type="checkbox" checked/>
                                                <label className="custom-control-label" for="noti-s-switch-4"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-small-3 m-0">Message and mail me on weekly product updates.</p>
                                </li>
                                <li className="mb-3">
                                    <div className="mb-1"><span className="text-bold-500">Email on Follow</span>
                                        <div className="float-right">
                                            <div className="custom-switch">
                                                <input className="custom-control-input" id="noti-s-switch-3" type="checkbox"/>
                                                <label className="custom-control-label" for="noti-s-switch-3"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-small-3 m-0">Mail me when someone follows me.</p>
                                </li>
                                <li className="mb-3">
                                    <div className="mb-1"><span className="text-bold-500">Announcements</span>
                                        <div className="float-right">
                                            <div className="checkbox">
                                                <input id="noti-s-checkbox-2" type="checkbox" checked/>
                                                <label for="noti-s-checkbox-2"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-small-3 m-0">Receive all the news and announcements from my clients.</p>
                                </li>
                                <li className="mb-3">
                                    <div className="mb-1"><span className="text-bold-500">Date and Time</span>
                                        <div className="float-right">
                                            <div className="checkbox">
                                                <input id="noti-s-checkbox-3" type="checkbox"/>
                                                <label for="noti-s-checkbox-3"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-small-3 m-0">Show date and time on top of every page.</p>
                                </li>
                                <li>
                                    <div className="mb-1"><span className="text-bold-500">Email on Comments</span>
                                        <div className="float-right">
                                            <div className="custom-switch">
                                                <input className="custom-control-input" id="noti-s-switch-2" type="checkbox" checked/>
                                                <label className="custom-control-label" for="noti-s-switch-2"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-small-3 m-0">Mail me when someone comments on my article.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</aside>
  )
}

export default SideNotification
