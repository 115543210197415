import React, { useState, useEffect } from 'react'
import notify from './../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { createNewUser, loginUser } from '../../redux/actions/authAction';
import { useNavigate } from 'react-router-dom'

const LoginHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [isProgressOn, setIsProgressOn] = useState(false);

    const onChangePhone = (e) => {
        // console.log(e.target.value);
        setPhone(e.target.value)
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }


    const isValidationValuesCorrect = () => {
        if (phone === "") {
            notify("الهاتف مطلوب", "error")
            return false;
        }

        if (password === "") {
            notify("كلمةالمرور مطلوبة", "error");
            return false;
        }
        return true;
    }

    const OnSubmit = async () => {
        if (!isValidationValuesCorrect()) {
            return;
        }

        setIsProgressOn(true)
        setLoading(true)

        await dispatch(loginUser({
            phoneNumber: phone,
            password,
        }))

        setLoading(false)
        setIsProgressOn(false)
    }

    const res = useSelector(state => state.authReducer.loginUser)
    useEffect(() => {
        if (loading === false) {
            if (res) {
                // console.log(res)
                if (res.data.success === false) {
                    notify(res.data.message, "error");
                }
                else {
                    if (res.data.data.token) {
                        localStorage.setItem("token", res.data.data.token)
                        // localStorage.setItem("user", JSON.stringify(res.data.data))
                        localStorage.setItem("phoneNumber", res.data.data.phoneNumber?.replace(/"/g, ''))
                        localStorage.setItem("userName", res.data.data.name?.replace(/"/g, ''))
                        localStorage.setItem("Role", res.data.data.roles[0]?.replace(/"/g, ''))
                        localStorage.setItem("refreshTokenExpiration", res.data.data.refreshTokenExpiration)

                        notify(res.data.message, "success")
                        setTimeout(() => {
                            window.location.href = "/"
                        }, 1500);
                    }
                }


            }
        }
    }, [loading])

    return [phone, password, onChangePhone, onChangePassword, OnSubmit, isProgressOn]
}

export default LoginHook