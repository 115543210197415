import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginHook from '../../hook/auth/login-hook';
import { ToastContainer } from 'react-toastify';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import $ from 'jquery';


export default function AdminLoginPage() {
	const [phone, password, onChangePhone, onChangePassword, OnSubmit, isProgressOn] = LoginHook();
	useEffect(() => {
		$(document).ready(function() {
			if ($('#login').length > 0) {
			  $('.main-content').css('margin', '0px');
			}
		  });
		  
	}, [])
	return (
		<section id="login" class="auth-height">
			<div class="row full-height-vh m-0">
				<div class="col-12 d-flex align-items-center justify-content-center">
					<div class="card overflow-hidden">
						<div class="card-content">
							<div class="card-body auth-img">
								<div class="row m-0">
									<div class="col-lg-6 d-flex justify-content-center align-items-center auth-img-bg p-3">
										<img 
											src="../../../main-assets/imgs/mainLogoDark.jpg"
											alt=""
											class="img-fluid"
											width="300"
											height="230"
											style={{borderRadius: ".35rem"}}
										/>
									</div>
									<div class="col-lg-6 col-12 px-4 py-3">
										<h4 class="mb-2 card-title">تسجيل الدخول</h4>
										<p>اهلا مجدداً, يرجا تسجيل الدخول لإدارة مطعمك.</p>
										<fieldset className="form-group mb-1">
										<label>رقم الهاتف</label>
										<input
											type="number"
											className="form-control "
											placeholder="رقم الهاتف"
											value={phone}
											onChange={onChangePhone}
										/>
									</fieldset>
									<fieldset className="form-group mb-1">
									<label>كلمة المرور</label>

									<input
										type="password"
										className="form-control "
										placeholder="الرمز"
										value={password}
										onChange={onChangePassword}
									/>
								</fieldset>

										{/* <div class="d-sm-flex justify-content-between mb-3 font-small-2">
											<div class="remember-me mb-2 mb-sm-0">
												<div class="checkbox auth-checkbox">
													<input type="checkbox" id="auth-ligin" />
													<label for="auth-ligin">
														<span>Remember Me</span>
													</label>
												</div>
											</div>
											<a href="auth-forgot-password.html">Forgot Password?</a>
										</div> */}
										<div class="d-flex justify-content-between flex-sm-row flex-column">
												

											
											{
											isProgressOn ?
												(<BtnLoaderComp/>) :
												(
												<button onClick={OnSubmit} class="btn btn-primary w-100 mt-2">
													تسجيل الدخول
												</button>
												)
											}
											
											
										</div>
										<hr />
{
									// 		<h6 class="text-primary text-center mb-2">او</h6>
									// 	<Link to={"/AdminRegisterPage"} class="btn bg-light-primary mb-2 mb-sm-0 m-auto d-block w-100">
									// 	انشاء حساب
									// </Link>
								}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
		</section>
	);
}
