import React from 'react';
import { useEffect } from 'react';
import FoodHook from '../../hook/foods/food-hook';
import BillHook from '../../hook/bill/bill-hook';
import TablesHook from '../../hook/table/table-hook';
import UserHook from '../../hook/users/user-hook';


const HomePage = () => {
	const [isCreated, name, description, price, isSpicy, deleted, onChangeName, onChangedescription, onChangeprice, onChangeisSpicy, onChangecategoryId, onChangedeleted, onImageChange, resGetFoods, resCreateFood, resGetCategories, OnSubmit, OnDeleteFood] = FoodHook();
	const [name1, onChangeName2, resGetRoles] = BillHook()
	const [tableNum, tableCapacity,seletedId, onSetTableNum, onSetTableCapacity, onSetseletedId,onEditTableNum, onEditTableCapacity, resGetTables, OnSubmit3, OnUpdate, OnDelete, isCreated3, isUpdated] = TablesHook();
	const [isUpdate, userId, name4, userName, phone, email, newPass, oldPass,confirmPass, userRole, resGetUser, resChangUserPassword, onSelectUserId, onChangeName4, onEditName, onChangeUserName, onEditUserName, onChangePhone, onEditPhone ,onChangeEmail, onEditEmail, onChandeNewPass, onChandeOldPass,onChandeConfirmPass, onEdituserRole,  OnDeleteUser, onChangeState , OnUpdateUser, OnChangeUserRole, OnChangeUserPassword]   = UserHook();
	
	useEffect(() => {
    // إنشاء عنصر السكربت الأول
    const script1 = document.createElement('script');
    script1.src = 'app-assets/vendors/js/chartist.min.js';
    script1.async = true;
    document.body.appendChild(script1);

    // إنشاء عنصر السكربت الثاني
    const script2 = document.createElement('script');
    script2.src = 'app-assets/js/dashboard1.js';
    script2.async = true;
    document.body.appendChild(script2);

    // إزالة السكربتات عند إزالة المكون
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);


	return (
		<div>
			<div className="row">
				<div className="col-xl-3 col-lg-6 col-md-6 col-12">
					<div className="card gradient-purple-love">
						<div className="card-content">
							<div className="card-body py-0">
								<div className="media pb-1">
									<div className="media-body white text-left">
										<h3 className="font-large-1 white mb-0">
										{
											resGetUser != null? (
												resGetUser?.data.length
											) :("0")
										}
										
										</h3>
										<span>عدد الحسابات</span>
									</div>
									<div className="media-right white text-right">
										<i className="ft-users font-large-1" />
									</div>
								</div>
							</div>
							<div
								id="Widget-line-chart"
								className="height-75 WidgetlineChart WidgetlineChartshadow mb-2"
							/>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-6 col-md-6 col-12">
					<div className="card gradient-ibiza-sunset">
						<div className="card-content">
							<div className="card-body py-0">
								<div className="media pb-1">
									<div className="media-body white text-left">
										<h3 className="font-large-1 white mb-0">
										{
											resGetFoods != null? (
												resGetFoods?.data.data.length
											) :("0")
										}
										</h3>
										<span>عدد الوجبات</span>
									</div>
									<div className="media-right white text-right">
										<i className="ft-feather font-large-1" />
									</div>
								</div>
							</div>
							<div
								id="Widget-line-chart1"
								className="height-75 WidgetlineChart WidgetlineChartshadow mb-2"
							/>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-lg-6 col-md-6 col-12">
					<div className="card gradient-mint">
						<div className="card-content">
							<div className="card-body py-0">
								<div className="media pb-1">
									<div className="media-body white text-left">
										<h3 className="font-large-1 white mb-0">
										{
											resGetTables != null? (
												resGetTables?.data.data.length
											) :("0")
										}
										</h3>
										<span>عدد الطاولات</span>
									</div>
									<div className="media-right white text-right">
										<i className="ft-archive font-large-1" />
									</div>
								</div>
							</div>
							<div
								id="Widget-line-chart2"
								className="height-75 WidgetlineChart WidgetlineChartshadow mb-2"
							/>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-6 col-md-6 col-12">
					<div className="card gradient-king-yna">
						<div className="card-content">
							<div className="card-body py-0">
								<div className="media pb-1">
									<div className="media-body white text-left">
										<h3 className="font-large-1 white mb-0">
										{
											resGetRoles != null? (
												resGetRoles?.data.data.length
											) :("0")
										}
										{
											// resGetRoles?.data.data?.length || 0
										}
										</h3>
										<span>عدد الفواتير</span>
									</div>
									<div className="media-right white text-right">
										<i className="ft-file-minus font-large-1" />
									</div>
								</div>
							</div>
							<div
								id="Widget-line-chart3"
								className="height-75 WidgetlineChart WidgetlineChartshadow mb-2"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">PRODUCTS SALES</h4>
						</div>
						<div className="card-content">
							<div className="card-body">
								<div className="chart-info mb-3 ml-3">
									<span
										className="gradient-purple-bliss d-inline-block rounded-circle mr-1"
										style={{ width: '15px', height: '15px;' }}
									/>
									Sales
									<span
										className="gradient-mint d-inline-block rounded-circle mr-1 ml-2"
										style={{ width: '15px', height: '15px;' }}
									/>
									Visits
								</div>
								<div id="line-area" className="height-350 lineArea" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row match-height">
				<div className="col-xl-4 col-lg-12 col-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Statistics</h4>
						</div>
						<div className="card-content">
							<div className="card-body">
								<p className="font-medium-2 text-center my-2">
									Last 6 Months Sales
								</p>
								<div
									id="Stack-bar-chart"
									className="height-300 Stackbarchart mb-2"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-8 col-lg-12 col-12">
					<div className="card shopping-cart">
						<div className="card-header pb-2">
							<h4 className="card-title mb-1">Shopping Cart</h4>
						</div>
						<div className="card-content">
							<div className="card-body p-0">
								<div className="table-responsive">
									<table className="table text-center m-0">
										<thead>
											<tr>
												<th>Image</th>
												<th>Product</th>
												<th>Quantity</th>
												<th>Status</th>
												<th>Amount</th>
												<th>Delete</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<img
														className="height-50"
														src="../../app-assets/img/elements/01.png"
														alt="Generic placeholder image"
													/>
												</td>
												<td>Espresso</td>
												<td>1</td>
												<td>
													<span className="badge badge-pill bg-light-primary cursor-pointer">
														Active
													</span>
												</td>
												<td>$19.94</td>
												<td>
													<i className="ft-trash-2" />
												</td>
											</tr>
											<tr>
												<td>
													<img
														className="height-50"
														src="../../app-assets/img/elements/15.png"
														alt="Generic placeholder image"
													/>
												</td>
												<td>iPhone</td>
												<td>2</td>
												<td>
													<span className="badge badge-pill bg-light-danger cursor-pointer">
														Disabled
													</span>
												</td>
												<td>$99.00</td>
												<td>
													<i className="ft-trash-2" />
												</td>
											</tr>
											<tr>
												<td>
													<img
														className="height-50"
														src="../../app-assets/img/elements/11.png"
														alt="Generic placeholder image"
													/>
												</td>
												<td>iMac</td>
												<td>1</td>
												<td>
													<span className="badge badge-pill bg-light-info cursor-pointer">
														Paused
													</span>
												</td>
												<td>$299.00</td>
												<td>
													<i className="ft-trash-2" />
												</td>
											</tr>
											<tr>
												<td>
													<img
														className="height-50"
														src="../../app-assets/img/elements/14.png"
														alt="Generic placeholder image"
													/>
												</td>
												<td>iWatch</td>
												<td>2</td>
												<td>
													<span className="badge badge-pill bg-light-success cursor-pointer">
														Active
													</span>
												</td>
												<td>$24.51</td>
												<td>
													<i className="ft-trash-2" />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row match-height">
				<div className="col-xl-8 col-lg-12 col-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title mb-0">Visit & Sales Statistics</h4>
						</div>
						<div className="card-content">
							<div className="card-body">
								<div className="chart-info mb-2">
									<span className="text-uppercase mr-3">
										<i className="fa fa-circle success font-small-2 mr-1" />{' '}
										Sales
									</span>
									<span className="text-uppercase">
										<i className="fa fa-circle info font-small-2 mr-1" /> Visits
									</span>
								</div>
								<div id="line-area2" className="height-400 lineAreaDashboard" />
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-4 col-lg-12 col-12">
					<div className="card gradient-purple-bliss">
						<div className="card-content">
							<div className="card-body">
								<h4 className="card-title white">Statistics</h4>
								<div className="p-2 text-center">
									<a className="white font-medium-1">Month</a>
									<a className="btn round bg-light-info mx-3 px-3">Week</a>
									<a className="white font-medium-1">Day</a>
								</div>
								<div className="my-3 text-center white">
									<div className="font-large-2 d-block mb-1">
										<span>$78.89</span>
										<i className="ft-arrow-up font-large-2" />
									</div>
									<span className="font-medium-1">Week2 +15.44</span>
								</div>
							</div>
							<div id="lineChart" className="height-250 lineChart lineChartShadow" />
						</div>
					</div>
				</div>
			</div>
			<div className="row match-height">
				<div className="col-xl-4 col-md-6 col-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Statistics</h4>
						</div>
						<div className="card-content">
							<p className="font-medium-2 text-center mb-0 mt-2">Hobbies</p>
							<div id="bar-chart" className="height-250 BarChartShadow BarChart" />

							<div className="card-body">
								<div className="row">
									<div className="col text-center">
										<span
											className="gradient-starfall d-block rounded-circle mx-auto mb-2"
											style={{ width: '10px', height: '10px;' }}
										/>
										<span className="font-medium-4 d-block mb-2">48</span>
										<span className="font-small-3">Sport</span>
									</div>
									<div className="col text-center">
										<span
											className="gradient-mint d-block rounded-circle mx-auto mb-2"
											style={{ width: '10px', height: '10px;' }}
										/>
										<span className="font-medium-4 d-block mb-2">9</span>
										<span className="font-small-3">Music</span>
									</div>
									<div className="col text-center">
										<span
											className="gradient-purple-bliss d-block rounded-circle mx-auto mb-2"
											style={{ width: '10px', height: '10px;' }}
										/>
										<span className="font-medium-4 d-block mb-2">26</span>
										<span className="font-small-3">Travel</span>
									</div>
									<div className="col text-center">
										<span
											className="gradient-ibiza-sunset d-block rounded-circle mx-auto mb-2"
											style={{ width: '10px', height: '10px;' }}
										/>
										<span className="font-medium-4 d-block mb-2">17</span>
										<span className="font-small-3">News</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-4 col-md-6 col-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title mb-0">User List</h4>
						</div>
						<div className="card-content">
							<div className="card-body">
								<div className="media pt-0 pb-2">
									<img
										className="mr-3 avatar"
										src="../../app-assets/img/portrait/small/avatar-s-12.png"
										alt="Avatar"
										width="35"
									/>
									<div className="media-body">
										<h4 className="font-medium-1 mb-0">Jessica Rice</h4>
										<p className="text-muted font-small-3 m-0">UX Designer</p>
									</div>
									<div className="mt-1">
										<div className="checkbox">
											<input type="checkbox" id="dash-checkbox1" checked="" />
											<label for="dash-checkbox1" />
										</div>
									</div>
								</div>
								<div className="media py-2">
									<img
										className="mr-3 avatar"
										src="../../app-assets/img/portrait/small/avatar-s-11.png"
										alt="Avatar"
										width="35"
									/>
									<div className="media-body">
										<h4 className="font-medium-1 mb-0">Jacob Rios</h4>
										<p className="text-muted font-small-3 m-0">
											HTML Developer
										</p>
									</div>
									<div className="mt-1">
										<div className="checkbox">
											<input type="checkbox" id="dash-checkbox2" />
											<label for="dash-checkbox2" />
										</div>
									</div>
								</div>
								<div className="media py-2">
									<img
										className="mr-3 avatar"
										src="../../app-assets/img/portrait/small/avatar-s-3.png"
										alt="Avatar"
										width="35"
									/>
									<div className="media-body">
										<h4 className="font-medium-1 mb-0">Russell Delgado</h4>
										<p className="text-muted font-small-3 m-0">
											Database Designer
										</p>
									</div>
									<div className="mt-1">
										<div className="checkbox">
											<input type="checkbox" id="dash-checkbox3" />
											<label for="dash-checkbox3" />
										</div>
									</div>
								</div>
								<div className="media py-2">
									<img
										className="mr-3 avatar"
										src="../../app-assets/img/portrait/small/avatar-s-22.png"
										alt="Avatar"
										width="35"
									/>
									<div className="media-body">
										<h4 className="font-medium-1 mb-0">Sara McDonald</h4>
										<p className="text-muted font-small-3 m-0">Team Leader</p>
									</div>
									<div className="mt-1">
										<div className="checkbox">
											<input type="checkbox" id="dash-checkbox4" checked="" />
											<label for="dash-checkbox4" />
										</div>
									</div>
								</div>
								<div className="media py-2">
									<img
										className="mr-3 avatar"
										src="../../app-assets/img/portrait/small/avatar-s-18.png"
										alt="Avatar"
										width="35"
									/>
									<div className="media-body">
										<h4 className="font-medium-1 mb-0">Janet Lucas</h4>
										<p className="text-muted font-small-3 m-0">
											Project Manger
										</p>
									</div>
									<div className="mt-1">
										<div className="checkbox">
											<input type="checkbox" id="dash-checkbox5" />
											<label for="dash-checkbox5" />
										</div>
									</div>
								</div>
								<div className="media py-2">
									<img
										className="mr-3 avatar"
										src="../../app-assets/img/portrait/small/avatar-s-21.png"
										alt="Avatar"
										width="35"
									/>
									<div className="media-body">
										<h4 className="font-medium-1 mb-0">Mark Carter</h4>
										<p className="text-muted font-small-3 m-0">
											HTML Developer
										</p>
									</div>
									<div className="mt-1">
										<div className="checkbox">
											<input type="checkbox" id="dash-checkbox6" checked />
											<label for="dash-checkbox6" />
										</div>
									</div>
								</div>
								<div className="text-center mt-2">
									<button type="button" className="btn bg-light-primary">
										Add New
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-4 col-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Project Stats</h4>
						</div>
						<div className="card-content">
							<p className="font-medium-2 text-center mb-0 mt-2">Project Tasks</p>
							<div id="donut-dashboard-chart" className="height-250 donut" />

							<div className="card-body">
								<div className="row mb-3">
									<div className="col">
										<span className="mb-1 text-muted d-block">
											23% - Started
										</span>
										<div className="progress" style={{ height: '5px;' }}>
											<div
												className="progress-bar bg-info"
												role="progressbar"
												style={{ width: '23%;' }}
												aria-valuenow="23"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
									<div className="col">
										<span className="mb-1 text-muted d-block">28% - Done</span>
										<div className="progress" style={{ height: '5px;' }}>
											<div
												className="progress-bar bg-success"
												role="progressbar"
												style={{ width: '28%;' }}
												aria-valuenow="28"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col">
										<span className="mb-1 text-muted d-block">
											35% - Remaining
										</span>
										<div className="progress" style={{ height: '5px;' }}>
											<div
												className="progress-bar bg-primary"
												role="progressbar"
												style={{ width: '35%;' }}
												aria-valuenow="35"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
									<div className="col">
										<span className="mb-1 text-muted d-block">
											14% - In Progress
										</span>
										<div className="progress" style={{ height: '5px;' }}>
											<div
												className="progress-bar bg-warning"
												role="progressbar"
												style={{ width: '14%;' }}
												aria-valuenow="14"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            <script src="app-assets/vendors/js/chartist.min.js"></script>
            <script src="app-assets/js/dashboard1.js"></script>
		</div>
	);
};

export default HomePage;
