import axios from "axios";
import { CHANGE_USER_PASSWORD, CHANGE_USER_ROLE, CHANGE_USER_STATE, DELETE_USER, GET_ALL_USERS, UPDATE_USERS } from "../type";
import { type } from "@testing-library/user-event/dist/type";


export const getAllUsers = () => async (dispatch) =>{
    try {
        const res = await axios({
            method: 'get',
            url: 'https://smartmenulocalapi.runasp.net/api/Users/GetAllUsersWithRolesAsync',
            
        });

        dispatch({
            type: GET_ALL_USERS,
            payload: res,
        })
    } catch (e) {
        dispatch ({
            type: GET_ALL_USERS,
            payload: e.response,
        })
    }
}


export const deletUser = (userId) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'delete',
            url: `https://smartmenulocalapi.runasp.net/api/Users/DeleteUser/${userId}`,
        });
        dispatch({
            type: DELETE_USER,
            payload: res,
        })
    } catch (e) {
        dispatch({
            type: DELETE_USER,
            payload: e.response,
        })
    }
}

export const changeUserState = (userID) => async(dispatch) => {
    try{
        const res = await axios ({
            method: 'post',
            url: `https://smartmenulocalapi.runasp.net/api/Users/ChangeUserStatus/${userID}`,
        });
        dispatch({
            type: CHANGE_USER_STATE,
            payload: res,
        })
    }catch (e) {
        dispatch({
            type: CHANGE_USER_STATE,
            payload: e.response,
        })
    }
}

export const updateUser = (data) => async(dispatch) => {
    const payload = data ? await putUpdateUser(data) : data;
    // console.log("payload: " + payload)
    // console.log(payload)
    dispatch({
        type: UPDATE_USERS,
        payload: payload,
    });
}
const putUpdateUser = async (data) => {
    try {
        // const res = await axios.put(`https://smartmenulocalapi.runasp.net/api/Users/UpdateUser`,data);
        const res = await axios({
            method: 'put',
            url: `https://smartmenulocalapi.runasp.net/api/Users/UpdateUser`,
            data: data
        })
        return res;
    } catch (error) {
        return error.response;
    }
}

// //
export const changeUserRole = (userId, data) => async (dispatch)=>{
    const payload = data ? await funChangeUserRole(userId, data) : data;
    dispatch({
        type: CHANGE_USER_ROLE,
        payload: payload
    })
}
const funChangeUserRole = async (userId, data) =>{
    try {
        const res = axios.post(`https://smartmenulocalapi.runasp.net/api/Users/ChangeUserRole/${userId}?selectedRoleName=${data}`)
        return res;
    } catch (error) {
        return error.response;
    }
}

// //
export const changeUserPassword = (userId, data) => async (dispatch)=>{
    const payload = data ? await funChangeUserPassword(userId, data) : data;
    dispatch({
        type: CHANGE_USER_PASSWORD,
        payload: payload
    })
}
const funChangeUserPassword = async (userId, data) =>{
    try {
        const res = await axios({
            method: 'post',
            url: `https://smartmenulocalapi.runasp.net/api/Users/ChangeUserPassword/${userId}`,
            data: data
        })
        return res;
    } catch (error) {
        return error.response;
    }
}



// export const updateUser = (data) => async (dispatch) => {
//     try {
//         if(data === null){
//             dispatch({
//                 type: CHANGE_USER_STATE,
//                 payload: data,
//             })
//             return;
//         }

//         const res = await axios({
//             method: 'put',
//             url: `https://smartmenulocalapi.runasp.net/api/Users/UpdateUser`,
//             data:data
//             });   

//             dispatch({
//                 type: CHANGE_USER_STATE,
//                 payload: res,
//             })
            
//     } catch (e) {
//         dispatch({
//             type: CHANGE_USER_STATE,
//             payload: e.response,
//         })
//     }


// }