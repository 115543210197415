import notify from './../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useId, useState } from 'react';
import { changeUserPassword, changeUserRole, changeUserState, deletUser, getAllUsers, updateUser } from './../../redux/actions/UserAction';

const UserHook = () => {
    const [loading, setloading] = useState(false);
    const [isDeleted, setisDeleted] = useState(false);
    const [isChangeState, setisChangeState] = useState(false);
    const [isUpdate, setisUpdate] = useState(false);


    const resGetUser = useSelector(state => state.userReduser.getAllUsers);
    const resDeleteUser = useSelector(state => state.userReduser.deleteUser);
    const resChangeUserState = useSelector(state => state.userReduser.changeUserState);
    const resUpdateUser = useSelector(state => state.userReduser.updataUser);
    const resChangUserRole = useSelector(state => state.userReduser.changUserRole);
    const resChangUserPassword = useSelector(state => state.userReduser.changUserPassword);

    const dispatch = useDispatch();

    const [userId, setuserId] = useState('')
    const [name, setname] = useState('')
    const [userName, setuserName] = useState('')
    const [phone, setphone] = useState('')
    const [email, setemail] = useState('')
    const [userRole, setuserRole] = useState('')
    const [oldPass, setoldPass] = useState('')
    const [newPass, setnewPass] = useState('')
    const [confirmPass, setconfirmPass] = useState('')

    // const onChangeUserId = (e) => {
    //     setuserId(e.target.value)
    // }
    const onSelectUserId = (value) => {
        setuserId(value)
    }

    const onChangeName = (e) => {
        setname(e.target.value)
    }
    const onEditName = (value) => {
        setname(value)
    }

    const onChangeUserName = (e) => {
        setuserName(e.target.value)
    }
    const onEditUserName = (value) => {
        setuserName(value)
    }

    const onChangePhone = (e) => {
        setphone(e.target.value)
    }
    const onEditPhone = (value) => {
        setphone(value)
    }

    const onChangeEmail = (e) => {
        setemail(e.target.value)
    }
    const onEditEmail = (value) => {
        setemail(value)
    }
    const onEdituserRole = (value) => {
        setuserRole(value)
    }

    const onChandeOldPass = (e) => {
        setoldPass(e.target.value)
    }

    const onChandeNewPass = (e) => {
        setnewPass(e.target.value)
    }
    const onChandeConfirmPass = (e) => {
        setconfirmPass(e.target.value)
    }

    const isValidationValuesCorrect = () => {
        if (name === "") {
            notify("الاسم مطلوب", "error")
            return false;
        }

        if (phone === "") {
            notify("الهاتف مطلوب", "error")
            return false;
        }

        if(email === ""){
            notify("الايميل مطلوب", "error");
            return false;
        }

        if(email === ""){
            notify("اسم المستخدم مطلوب", "error")
            return false;
        }

        return true;
    }

    ////////////////////////////////
    useEffect( () => {
        if(loading === false){
            dispatch(getAllUsers());
        }
    }, [loading])

    ////////////////////////////////
    const OnDeleteUser = async (userId) =>{
        await dispatch(deletUser(userId));
        setloading(true);
        setisDeleted(true);
    }
    useEffect(() => {
        if (isDeleted && resDeleteUser) {
            const { success, message } = resDeleteUser.data;
            notify(message, success ? "success" : "error");
            setloading(false);
            setisDeleted(false);
        }
    }, [isDeleted]);
    

    ////////////////////////////////
    const onChangeState = async (userId) =>{
        await dispatch(changeUserState(userId));
        setloading(true);
        setisChangeState(true);
    }
    useEffect(() => {
            if(resChangeUserState && isChangeState === true){
                if(resChangeUserState.data.success === true){
                    notify(resChangeUserState.data.message, "success");
                }else{
                    notify(resChangeUserState.data.message, "error");
                }
            }
        
        setloading(false);
        setisChangeState(false);
    }, [isChangeState])
    

    ////////////
    const OnUpdateUser = async ()=>{
        if(isValidationValuesCorrect && userId){

            await dispatch(updateUser({
                userId: userId,
                userName: userName,
                name: name,
                phoneNumber: phone,
                email: email,
            }))

            setloading(true)
            setisUpdate(true)
        }
    }
    useEffect(() => {

        if(isUpdate && resUpdateUser){
            if(resUpdateUser.data.success === true){
                notify(resUpdateUser.data.message, "success");
            }else{
                notify(resUpdateUser.data.message, "error");
            }
        }
        setloading(false)
        setisUpdate(false)
    }, [isUpdate])

    ////////////////////////////////
    const OnChangeUserRole = ()=>{
        if(userRole && userId){
            setisUpdate(true)
            setloading(true)
            dispatch(changeUserRole(userId, userRole))
        }
    }
    useEffect(()=>{
        if(isUpdate && resChangUserRole){
            if(resChangUserRole.data.success === true){
                notify(resChangUserRole.data.message, "success");
            }else{
                notify(resChangUserRole.data.message, "error");
            }
        }
        setisUpdate(false)
        setloading(false)
    },[isUpdate])

    ////////////////////////////////
    const OnChangeUserPassword = ()=>{
        if (newPass !== confirmPass) {
            notify("الرمز الجديد غير متشابه", "error")
            return false;
        }
        if(newPass && userId){
            setisUpdate(true)
            setloading(true)
            dispatch(changeUserPassword(userId, {
                currentPassword: oldPass,
                newPassword: newPass,
                confirmPassword:  confirmPass,
            }))
        }
    }
    useEffect(()=>{
        if(isUpdate && resChangUserPassword){
            if(resChangUserPassword.data.success === true){
                notify(resChangUserPassword.data.message, "success");
            }else{
                notify(resChangUserPassword.data.message, "error");
            }
        }
        setisUpdate(false)
        setloading(false)
    },[isUpdate])

    return [isUpdate, userId, name, userName, phone, email, newPass, oldPass,confirmPass, userRole, resGetUser, resChangUserPassword, onSelectUserId, onChangeName, onEditName, onChangeUserName, onEditUserName, onChangePhone, onEditPhone ,onChangeEmail, onEditEmail, onChandeNewPass, onChandeOldPass,onChandeConfirmPass, onEdituserRole,  OnDeleteUser, onChangeState , OnUpdateUser, OnChangeUserRole, OnChangeUserPassword] 
}
export default UserHook

