import React from 'react';
import { Link } from 'react-router-dom';
import TablesHook from '../../hook/table/table-hook';

import { ConfirmDeleteComp } from '../../Components/sheardComponent/ConfirmDeleteComp';
import { FixUpdateDataTableHook } from '../../Components/sheardComponent/FixUpdateDataTableHook';
import { ChangStateComp } from '../../Components/sheardComponent/ChangStateComp';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import MainLoaderComp from '../../Components/sheardComponent/MainLoaderComp';
import { ChangeFreezeCom } from '../../Components/sheardComponent/ChangeFreezeCom';

export default function AdminTablesPage() {

	const [tableNum, tableCapacity,seletedId, onSetTableNum, onSetTableCapacity, onSetseletedId,onEditTableNum, onEditTableCapacity, resGetTables, OnSubmit, OnUpdate, OnDelete, isCreated, isUpdated] = TablesHook();
	// تعديه اول شي المتغير لي فيه البيانات حق الداتا تيبل و ثاني برمتر سلكتور الجدول نفسه
	FixUpdateDataTableHook(resGetTables, '.dom-jQuery-events');

	// const onUpdate = ()=>{
	// 	OnUpdate(seletedId)
	// }
	const onUpdate = ()=>{
		OnUpdate(seletedId);
		console.log(seletedId);
	}

    return (
		<div>
			<div className="productsViewSection">
				<div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
					<button
						className="main-btn"
						data-toggle="modal"
						data-target="#iconForm"
						style={{ padding: '7px 35px', fontSize: '18px', fontWeight: 'bold' }}
					>
						<span>اضافة طاولة</span>
						<div className="svg-wrapper-1">
							<div className="svg-wrapper">
								<i className="fa-solid fa-circle-plus" />
							</div>
						</div>
					</button>

					<div className="btns">
						<Link to="/ProdutsPage" className="btn btn-outline-primary mr-1 mb-1">
							<i className="fa-solid fa-grip" />
						</Link>
						<a href="/ProdutsTablePage" className="btn btn-primary mr-1 mb-1">
							<i className="fa-solid fa-list" />
						</a>
					</div>
				</div>

				<div className="table-responsive my-4">
			
                {resGetTables != null ? (
                    <table className="table table-striped table-bordered dom-jQuery-events">
                    <thead>
                        <tr>

                            <th>
							رقم الطاولة
							</th>

                            <th>
							سعة الطاولة
							</th>

                            <th>
							مجمد
							</th>

                            <th>
							الإجرائات
							</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            resGetTables?.data.data.map((item) =>{
                                return (
							<tr>
							<td>
                                <span>{item.tableNumber}</span>
                            </td>

                            <td>
                                <span>{item.capacity}</span>
                            </td>

							<td>
							{item.isDeleted ? (
								<i  className="fa-regular fa-circle-check statusData statusActive freezeIcon" />
							) : (
								<i className="fa-regular fa-circle-xmark statusData statusNActive" />
							)}
						</td>
							<td>
							<div className="groupOptions">
								
							<button
							className="edit-post gold"
							data-toggle="modal"
							data-target="#EditIconForm"
							onClick={() => {onSetseletedId(item.id)
								onEditTableNum(item.tableNumber)
								onEditTableCapacity(item.capacity)
							}}
						>
							<span className="edit-tooltip">تعديل</span>
							<span className="edit-icon">
								<i className="ft-edit-2" />
							</span>
						</button>

							<ChangeFreezeCom onChangeStateFun={OnDelete} nameItem={item.tableNumber} idItem={item.id}/>
							</div>
						</td>

                        </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                ):
                    (<MainLoaderComp/>)
                }
            		
				</div>

				{
					//AddPupUp Modal
				}
				<div
					className="modal fade text-left"
					id="iconForm"
					tabindex="-1"
					role="dialog"
					aria-labelledby="myModalLabel34"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="myModalLabel34">
									إضافة طاولة جديدة
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="ft-x font-medium-2 text-bold-700" />
									</span>
								</button>
							</div>
								<div className="modal-body">
									<div className="">
										<div className="form-group mb-2">
											<label>رقم الطاولة</label>
											<div className="controls">
												<input
													type="text"
													name="text"
													className="form-control"
													// data-validation-required-message="هذا الحقل مطلوب"
													placeholder="رقم الطاولة"
													value={tableNum}
													onChange={onSetTableNum}
												/>
											</div>
										</div>
										<div className="form-group mb-2">
											<label>سعة الطاولة</label>
											<div className="controls">
												<input
													type="text"
													name="text"
													className="form-control"
													// data-validation-required-message="هذا الحقل مطلوب"
													placeholder="رقم الطاولة"
													value={tableCapacity}
													onChange={onSetTableCapacity}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<input
										type="reset"
										className="btn bg-light-secondary"
										data-dismiss="modal"
										value="الغاء"
									/>
									{isCreated ? (
										<button onClick={OnSubmit} className="btn btn-primary">
											تأكيد
										</button>
									) : (<BtnLoaderComp/>) 
									}
									
								</div>
						</div>
					</div>
				</div>

				{
					//editPupUp Modal
				}
				<div
					className="modal fade text-left"
					id="EditIconForm"
					tabindex="-1"
					role="dialog"
					aria-labelledby="myModalLabel34"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="myModalLabel34">
									تعديل الطاولة
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="ft-x font-medium-2 text-bold-700" />
									</span>
								</button>
							</div>
								<div className="modal-body">
									<div className="">
										<div className="form-group mb-2">
											<label>رقم الطاولة</label>
											<div className="controls">
												<input
													type="text"
													name="text"
													className="form-control"
													// data-validation-required-message="هذا الحقل مطلوب"
													placeholder="رقم الطاولة"
													value={tableNum}
													onChange={onSetTableNum}
												/>
											</div>
										</div>
										<div className="form-group mb-2">
											<label>سعة الطاولة</label>
											<div className="controls">
												<input
													type="text"
													name="text"
													className="form-control"
													// data-validation-required-message="هذا الحقل مطلوب"
													placeholder="رقم الطاولة"
													value={tableCapacity}
													onChange={onSetTableCapacity}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<input
										type="reset"
										className="btn bg-light-secondary"
										data-dismiss="modal"
										value="الغاء"
									/>
									{!isUpdated ? (
										<button onClick={onUpdate} className="btn btn-primary">
											تأكيد
										</button>
									) : (<BtnLoaderComp/>) 
									}
									
								</div>
						</div>
					</div>
				</div>


			</div>
		</div>
        
	);
}
