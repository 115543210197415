import { CREATE_TABLE, DELETE_TABLE, GET_ALL_TABLES, UPDATE_TABLE } from "../type";

const inital = {
    roles: [],
}
const tableReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_TABLES:
            return {
                ...state,
                getAllTables: action.payload,
            }
        case CREATE_TABLE:
            return {
                ...state,
                createTable: action.payload,
            }
        case UPDATE_TABLE:
            return {
                ...state,
                updateTable: action.payload,
            }
        case DELETE_TABLE:
            return{
                ...state,
                deleteTable: action.payload,
            }
        default:
            return state;
    }
}
export default tableReducer