import { CREATE_NEW_ROLE, DELETE_ROLE, GET_ALL_ROLES } from "../type";

const inital = {
    roles: [],
}
const roleReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_ROLES:
            return {
                ...state,
                getAllroles: action.payload,
            }
        case CREATE_NEW_ROLE:
            return {
                ...state,
                createNewRole: action.payload,
            }
        case DELETE_ROLE:
        return {
            ...state,
            deleteRole: action.payload,
        }
        default:
            return state;
    }
}
export default roleReducer