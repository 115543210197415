import { CREATE_NEW_FOOD, DELETE_FOOD, GET_ALL_FOODS } from "../type"


const inital = {
    roles: [],
}

const foodReduser = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_FOODS:
            return {
                ...state,
                getAllFoods: action.payload,
            }
        case CREATE_NEW_FOOD:
            return {
                ...state,
                createNewFood: action.payload,
            }
        case DELETE_FOOD:
            return {
                ...state,
                deleteFood: action.payload,
            }
        // case CHANGE_USER_STATE:
        //     return {
        //         ...state,
        //         changeFoodState: action.payload,
        //     }
        // case UPDATE_USERS:
        //     return {
        //         ...state,
        //         updataFood: action.payload, // هنا يتم تحديث الخاصية الجديدة
        //     }
        default:
            return state;
    }
}

export default foodReduser
