
import notify from './../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { createNewRole, deleteRole, getAllRoles } from '../../redux/actions/roleAction';

const RoleHook = () => {
    const [loading, setLoading] = useState(false);
    const [isCreated, setIsCreated] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);

    const dispatch = useDispatch();

    const [name, setName] = useState('');

    const onChangeName = (e) => {
        setName(e.target.value)
    }

    const isValidationValuesCorrect = () => {
        if (name === "") {
            notify("اسم الدور مطلوب", "error")
            return false;
        }
        return true;
    }
    const resGetRoles = useSelector(state => state.roleReducer.getAllroles);
    const resAddRoles = useSelector(state => state.roleReducer.createNewRole);
    const resDeleteRoles = useSelector(state => state.roleReducer.deleteRole);

    useEffect(()=>{
        if(loading === false){
            dispatch(getAllRoles());       
        }
        else{
            console.log(resAddRoles +"a")
            if(resAddRoles && resAddRoles !== null){
                console.log(resAddRoles +"z")
                if(resAddRoles.data.success === true){
                    notify(resAddRoles.data.message, "success");
                }
                else{
                    notify(resAddRoles.data.message, "error");
                }
                dispatch(createNewRole(null));
            }
            setLoading(false);
        }
    }, [loading])

    const OnSubmit = async () => {
        if (! isValidationValuesCorrect()){
          return;
        }
        setIsCreated(false);
        
        await dispatch(createNewRole(name));
        setLoading(true);
        setIsCreated(true);
        console.log(resAddRoles +"o")
    }



    useEffect(()=>{
        if(isDeleted === true){
            if(resDeleteRoles){
                if(resDeleteRoles.data.success === true){
                    notify(resDeleteRoles.data.message, "success");
                }
                else{
                    notify(resDeleteRoles.data.message, "error");
                }    
            }
            setLoading(false);
        }
        setIsDeleted(false);
    }, [isDeleted]);
    
    const OnDeleteRole = async(roleName) => {
        await dispatch(deleteRole(roleName));
        setIsDeleted(true);
        setLoading(true);
    }


    return [name, onChangeName, resGetRoles, OnSubmit, isCreated, OnDeleteRole]
}

export default RoleHook