import React from 'react'
import BigCardCOM from './../../Components/sheardComponent/BigCardCOM';
import DateTableWithImg from './../../Components/sheardComponent/DateTableWithImg';

export default function ReviewsLocalPage() {
  return (
    <div>
    <BigCardCOM/>
    <DateTableWithImg/>
    </div>
  )
}
