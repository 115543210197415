import axios from "axios";
import { CREATE_TABLE, DELETE_TABLE, GET_ALL_TABLES ,UPDATE_TABLE} from "../type";

//get all table 
export const getAllTables = () => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: 'https://smartmenulocalapi.runasp.net/api/Tables/GetAllTables-Async',
            });   

            dispatch({
                type: GET_ALL_TABLES,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: GET_ALL_TABLES,
            payload: e.response,
        })
    }   
}


// 
export const createTable =  (date) => async (dispatch) => {
    const payload = date ? await postTable(date) : date;
    dispatch({
        type: CREATE_TABLE,
        payload
    })
}
const postTable = async (date) => {
    try {
        const res = await axios.post(`https://smartmenulocalapi.runasp.net/api/Tables/Add-New-Table-Async`, date)
        return res;
    } catch (error) {
        return error.response;
    }
}


// 
export const updateTable = (date) => async (dispatch)=>{
    const payload = date ? await putUpdateTable(date) : date;
    dispatch({
        type: UPDATE_TABLE,
        payload: payload,
    })
}

const putUpdateTable = async (date) => {
    try {
        const res = await axios({
            method: 'put',
            url: `https://smartmenulocalapi.runasp.net/api/Tables/UpdateTable`,
            data: date
        })
        return res;
    } catch (error) {
        return error.response;
    }
}


export const deleteTable =  (data) => async (dispatch)=>{
    const payload = data? await deleteTableFun(data) : data;
    dispatch({
        type: DELETE_TABLE,
        payload: payload,
    })
}
 const deleteTableFun = async (data) =>{
    try {
        const res = await axios({
            method: 'delete',
            url: `https://smartmenulocalapi.runasp.net/api/Tables/Delete-TableById-Async/${data}`,
        });
        return res;
    } catch (error) {
        return error.response;
    }
 }

// export const createTable2 = (data) => async (dispatch) => {
//     try {
//         if(data === null){
//             dispatch({
//                 type: CREATE_TABLE,
//                 payload: data,
//             })
//             return;
//         }

//         const res = await axios({
//             method: 'post',
//             url: `https://smartmenulocalapi.runasp.net/api/Tables/Add-New-Table-Async`,
//             data: data
//             });   

//             dispatch({
//                 type: CREATE_TABLE,
//                 payload: res,
//             })
            
//     } catch (e) {
//         dispatch({
//             type: CREATE_TABLE,
//             payload: e.response,
//         })
//     }
// }