import React from 'react';
import { Link } from 'react-router-dom';
import BillHook from '../../hook/bill/bill-hook';

import { ConfirmDeleteComp } from '../../Components/sheardComponent/ConfirmDeleteComp';
import { FixUpdateDataTableHook } from '../../Components/sheardComponent/FixUpdateDataTableHook';
import { ChangStateComp } from '../../Components/sheardComponent/ChangStateComp';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import MainLoaderComp from '../../Components/sheardComponent/MainLoaderComp';
import { useEffect } from 'react';

export default function AdminBillsPage() {
	const [name, onChangeName, resGetRoles] = BillHook()
    	// تعديه اول شي المتغير لي فيه البيانات حق الداتا تيبل و ثاني برمتر سلكتور الجدول نفسه
	FixUpdateDataTableHook(resGetRoles, '.dom-jQuery-events');
    useEffect(() => {
        console.log(resGetRoles)
        }, [resGetRoles])
    return (
		<div>
			<div className="productsViewSection">
				{/* <div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
	

					<div className="btns">
						<Link to="/ProdutsPage" className="btn btn-outline-primary mr-1 mb-1">
							<i className="fa-solid fa-grip" />
						</Link>
						<a href="/ProdutsTablePage" className="btn btn-primary mr-1 mb-1">
							<i className="fa-solid fa-list" />
						</a>
					</div>
				</div> */}

				<div className="table-responsive my-4">
			
                {resGetRoles != null ? (
                    <table className="table table-striped table-bordered dom-jQuery-events">
                    <thead>
                        <tr>

			    			<th>
                            رقم الفاتورة
                            </th>
            
                            <th>
                            رقم الطاولة
                            </th>
            
                            <th>
                            اسم النادل
                            </th>

                            <th>
                            الخصم
                            </th>

                            <th>
                            اجمالي المبلغ
                            </th>


                            <th>
                            الحالة
                            </th>

                            <th>
                            وقت بدء الطلب
                            </th>

                            <th>
                            وقت التأكيد
                            </th>

                            <th>
                            وقت الإلغاء
                            </th>



                            <th>
                            وقت التوصيل
                            </th>


                            
                            <th>
                            الوقت مابين الطلب والتأكبد
                            </th>
                            
                            <th>
                            اجمالي الوقت
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            resGetRoles?.data.data.map((item) =>{
                                return (
                                    <tr>



							<td>
                                <span>{item.id}</span>
                            </td>
                       

                            <td>
                                <span>{item.tableName}</span>
                            </td>
{
                            // <td>
                            //     <span>{item.guestId}</span>
                            // </td>
                        }

                            <td>
                                <span>{item.waiterName}</span>
                            </td>
                        

					

                            <td>
                                <span>{item.discount}</span>
                            </td>

                            <td>
                                <span>{item.totalPriceOfBill}</span>
                            </td>

                            <td>
                                <span>{item.statusInArabic}</span>
                            </td>

                            <td>
                                <span>{item.createdAt}</span>
                            </td>

                            <td>
                                <span>{item.confirmedAt}</span>
                            </td>

                            <td>
                            <span>{item.canceledAt}</span>
                        </td>
                   

                            <td>
                                <span>{item.deliveredAt}</span>
                            </td>



                            <td>
                                <span>{item.timeBetweenSubmiteAndConfirm}</span>
                            </td>

                            <td>
                                <span>{item.totalTimeFromSubmitToDeliver}</span>
                            </td>

										
                        </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                ):
                    (<MainLoaderComp/>)
                }
            		
				</div>

				{
					//PupUp Modal
				}
				{/* <div
					className="modal fade text-left"
					id="iconForm"
					tabindex="-1"
					role="dialog"
					aria-labelledby="myModalLabel34"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="myModalLabel34">
									رقم الطاولة الجديدة
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i className="ft-x font-medium-2 text-bold-700" />
									</span>
								</button>
							</div>
								<div className="modal-body">
									<div className="">
										<div className="form-group mb-2">
											<label>الطاولة</label>
											<div className="controls">
												<input
													type="text"
													name="text"
													className="form-control"
													// data-validation-required-message="هذا الحقل مطلوب"
													placeholder="رقم الطاولة"
                                                    value={name}
												    onChange={onChangeName}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<input
										type="reset"
										className="btn bg-light-secondary"
										data-dismiss="modal"
										value="الغاء"
									/>
									{isCreated ? (
										<button onClick={OnSubmit} className="btn btn-primary">
											تأكيد
										</button>
									) : (<b>Loading...</b>) 
									}
									
								</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
        
	);
}
