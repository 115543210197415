export const LOGIN_USER = "LOGIN_USER"

export const GET_ALL_USERS = "GET_ALL_USERS"
export const CREATE_NEW_USER = "CREATE_NEW_USER"
export const DELETE_USER = "DELETE_USER"
export const UPDATE_USERS = "UPDATE_USERS"
export const CHANGE_USER_STATE = "CREATE_NEW_USER"
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE"
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD"

export const GET_ALL_FOODS = "GET_ALL_FOODS"
export const CREATE_NEW_FOOD = "CREATE_NEW_FOOD"
export const DELETE_FOOD = "DELETE_FOOD"
// export const UPDATE_USERS = "UPDATE_USERS"
// export const CHANGE_USER_STATE = "CREATE_NEW_USER"
// export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE"

export const GET_ALL_ROLES = "GET_ALL_ROLES"
export const CREATE_NEW_ROLE = "CREATE_NEW_ROLE"
export const DELETE_ROLE = "DELETE_ROLE"

export const CREATE_CATEGORY = "CREATE_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"

export const GET_ALL_TABLES = "GET_ALL_TABLES"
export const CREATE_TABLE = "CREATE_TABLE"
export const UPDATE_TABLE = "UPDATE_TABLE"
export const DELETE_TABLE = "DELETE_TABLE"

export const GET_ALL_BILLS = "GET_ALL_BILLS"

export const GET_ERROR = "GET_ERROR"

export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY"
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY"

export const GET_ALL_BRAND = "GET_ALL_BRAND"
export const CREATE_BRAND = "CREATE_BRAND"

export const CREATE_PRODUCTS = "CREATE_PRODUCTS"
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS"

export const GET_PRODUCT_DETALIS = "GET_PRODUCT_DETALIS"
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY"
export const GET_ONE_BRAND = "GET_ONE_BRAND"
export const GET_PRODUCT_LIKE = "GET_PRODUCT_LIKE"
export const DELETE_PRODUCTS = "DELETE_PRODUCTS"
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS"
export const GET_CURERNT_USER = "GET_CURERNT_USER"
export const FOREGT_PASSWORD = "FOREGT_PASSWORD"
export const VERIFY_PASSWORD = "VERIFY_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"


