import React from 'react'

export default function BigCardCOM() {
  return (
    <div className="TopOfProductsViewSection row match-height ">
{    // <div className="col-md-6 col-12">
    //     <div
    //         className="card card-inverse bg-warning text-center"
    //         style={{ height: '280px;' }}
    //     >
    //         <div className="card-content">
    //             <div className="card-body">
    //                 <div className="row d-flex">
    //                     <div className="col align-self-center">
    //                         <img
    //                             src="../../app-assets/img/elements/01.png"
    //                             alt="element 01"
    //                             width="190"
    //                             className="mt-sm-2"
    //                         />
    //                     </div>
    //                     <div className="col align-self-center">
    //                         <h4 className="card-title mb-3">Brand Minute</h4>
    //                         <p className="card-text">
    //                             Donut toffee candy brownie soufflé macaroon.
    //                         </p>
    //                         <button className="btn btn-warning btn-darken-3">
    //                             Buy Now
    //                         </button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    // <div className="col-md-6 d-md-block d-none">
    //     <div
    //         className="card card-inverse bg-primary text-center"
    //         style={{ height: '280px;' }}
    //     >
    //         <div className="card-content">
    //             <div className="card-body mt-sm-2">
    //                 <img
    //                     src="../../app-assets/img/elements/06.png"
    //                     alt="element 02"
    //                     width="235"
    //                     className="mb-3"
    //                 />
    //                 <h3 className="card-title">Ceramic Bottle</h3>
    //                 <p className="card-text">456 items</p>
    //             </div>
    //         </div>
    //     </div>
    // </div>
}
</div>
  )
}
