// ConfirmDelete.js
import React from 'react';
import Swal from 'sweetalert2';

export function ConfirmDeleteComp({ onDeleteFun, nameItem , idItem }) {
    const confirmDelete = () => {
        Swal.fire({
            title: 'هل انت متاكد من الحذف؟',
            title: `هل انت متاكد من حذف ${nameItem} ؟`,
            text: "لن تكون قادر على استعادتة !",
            icon: "warning",
            iconColor: 'var(--red)',
            showCancelButton: true,
            confirmButtonColor: 'var(--red)',
            // cancelButtonColor: 'var(--red)',
            confirmButtonText: 'متاكد',
            cancelButtonText: 'إلغاء',
                // تغيير كلاسات الأزرار
            customclassName: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn bg-light-secondary ml-1'
            }
        }).then(function (result) {
            if (result.value) {
                onDeleteFun(idItem);
            }
        });
    };

    return (
        <button className="edit-post red confirm-text" onClick={confirmDelete}>
            <span className="edit-tooltip">حذف</span>
            <span  className="edit-icon">
                <i className="ft-trash" />
            </span>
        </button>
    );
}
