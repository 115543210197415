import React from 'react'

const TopBtn = () => {
  return (
    <button className="btn btn-primary scroll-top" type="button"><i className="ft-arrow-up"></i></button>

  )
}

export default TopBtn
