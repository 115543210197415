

import { CREATE_NEW_USER,RESET_PASSWORD,VERIFY_PASSWORD, FOREGT_PASSWORD, GET_CURERNT_USER, LOGIN_USER } from '../type'

import axios from 'axios';

//create new user 
export const createNewUser = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: 'https://smartmenulocalapi.runasp.net/api/Auth/register',
            data: data,
            });   

            dispatch({
                type: CREATE_NEW_USER,
                payload: res,
                loading: true
            })
            
    } catch (e) {
        dispatch({
            type: CREATE_NEW_USER,
            payload: e.response,
        })
    }
}

//login  user 
export const loginUser = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: 'https://smartmenulocalapi.runasp.net/api/Auth/login',
            data: data,
            });   

            dispatch({
                type: LOGIN_USER,
                payload: res,
            })
            
    } catch (e) {
        dispatch({
            type: LOGIN_USER,
            payload: e.response,
        })
    }
}
