import { CREATE_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORY, UPDATE_CATEGORY } from "../type"

const inital = {
    roles: [],
}

const categoryReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_CATEGORY:
            return {
                ...state,
                getAllCategories: action.payload,
            }
        case CREATE_CATEGORY:
            return {
                ...state,
                createNewCategory: action.payload,
            }
        case UPDATE_CATEGORY:
            return {
               ...state,
                updateCategory: action.payload,
            }
        case DELETE_CATEGORY:
        return {
            ...state,
            deleteCategory: action.payload,
        }
        default:
            return state;
    }
}
export default categoryReducer