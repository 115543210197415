import React from 'react'
import { Link } from 'react-router-dom';
import DateTableWithImg from '../../Components/sheardComponent/DateTableWithImg';
export default function WorkersPage() {
  return (
    <div>
    <div className="productsViewSection">
    <div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
    <button className="main-btn" style={{    padding: "7px 35px",fontSize: "18px",fontWeight: "bold"}}>
        <span>اضافة طعام</span>
        <div className="svg-wrapper-1">
            <div className="svg-wrapper">
                <i className="fa-solid fa-circle-plus" />
            </div>
        </div>
    </button>

        <div className="btns">
            <Link to="/ProdutsPage" className="btn btn-outline-primary mr-1 mb-1">
                <i className="fa-solid fa-grip" />
            </Link>
            <a href="/ProdutsTablePage" className="btn btn-primary mr-1 mb-1">
                <i className="fa-solid fa-list" />
            </a>
        </div>
    </div>

<DateTableWithImg/>
</div>
    </div>
  )
}
