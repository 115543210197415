import { GET_ALL_BILLS } from "../type";

const inital = {
    roles: [],
}
const billReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_BILLS:
            return {
                ...state,
                getAllBills: action.payload,
            }
        default:
            return state;
    }
}
export default billReducer