import axios from 'axios';
import { CREATE_NEW_FOOD, DELETE_FOOD, GET_ALL_FOODS } from '../type';

export const getAllFoods = () => async (dispatch) => {
	try {
		const res = await axios({
			method: 'get',
			url: 'https://smartmenulocalapi.runasp.net/api/Foods/GetAllFoodsAsync'
		});
		dispatch({
			type: GET_ALL_FOODS,
			payload: res
		});
	} catch (e) {
		dispatch({
			type: GET_ALL_FOODS,
			payload: e.response
		});
	}
};

export const createNewFood = (foodData) => async (dispatch) => {
    try {
        const response = await axios({
            url: 'https://smartmenulocalapi.runasp.net/api/Foods/AddNew-Food-WithIts-Images-Async',
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: foodData
        });
        
        console.log("createNewFood");
      console.log(response);
      dispatch({ type: CREATE_NEW_FOOD, payload: response });

    //   if (response.status === 200) {
    //     dispatch({ type: CREATE_NEW_FOOD, payload: response.data });
    //   } else {
    //     dispatch({ type: CREATE_NEW_FOOD, error: 'Failed to add new food' });
    //   }
    } catch (error) {
      dispatch({ type: CREATE_NEW_FOOD, payload: error.response });
    }
  };


export const deletFood = (foodId) => async (dispatch) => {
	try {
		console.log('deleting action');
		const res = await axios({
			method: 'delete',
			url: `https://smartmenulocalapi.runasp.net/api/Foods/DeleteFoodAsync/${foodId}`
		});
		dispatch({
			type: DELETE_FOOD,
			payload: res
		});
	} catch (e) {
		dispatch({
			type: DELETE_FOOD,
			payload: e.response
		});
	}
};
