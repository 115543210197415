import { combineReducers } from 'redux'
import authReducer from './authReducer'
import roleReducer from './roleReducer'
import categoryReducer from './categotyReducer'
import tableReducer from './tableReducer'
import billReducer from './billReducer'
import userReduser from './userReducer'
import foodReduser from './foodReduser'

export default combineReducers({
    authReducer: authReducer,
    roleReducer: roleReducer,
    categoryReducer: categoryReducer,
    tableReducer: tableReducer,
    billReducer: billReducer,
    userReduser: userReduser,
    foodReduser: foodReduser,
})