
import notify from './../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { createNewUser } from '../../redux/actions/authAction';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { getAllRoles } from '../../redux/actions/roleAction';

const RegisterHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [isProgressOn, setIsProgressOn] = useState(false);

    const [selectedRole, setSelectedRole] = useState('');
    
    const handleSelectRoleChange = (event) => {
        console.log(event.target.value);
        setSelectedRole(event.target.value);
      };

    const onChangeName = (e) => {
        setName(e.target.value)
    }
    const onChangePhone = (e) => {        
        setPhone(e.target.value)

    }
    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }
    const onChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }
    const isValidationValuesCorrect = () => {
        if (name === "") {
            notify("الاسم مطلوب", "error")
            return false;
        }

        if (phone === "") {
            notify("الهاتف مطلوب", "error")
            return false;
        }

        if(password === ""){
            notify("كلمةالمرور مطلوبة", "error");
            return false;
        }

        if(confirmPassword === ""){
            notify("تأكيد كلمة المرور", "error")
            return false;
        }
        
        if (password != confirmPassword) {
            notify("كلمتي المرور غير متطابقتين", "error")
            return false;
        }

        return true;
    }

    const resAuth = useSelector(state => state.authReducer.createUser);
    const resRoles = useSelector(state => state.roleReducer.getAllroles);

    //save data
    const OnSubmit = async () => {
        if (! isValidationValuesCorrect()){
            return;
        }
        
        setIsProgressOn(true)
        setLoading(true);
        
        console.log(selectedRole);
        await dispatch(createNewUser({
            fullName:name,
            password,
            phoneNumber:phone,
            roleName: selectedRole
        }));

        setLoading(false);
        setIsProgressOn(false)
    }

    useEffect(()=>{
        
        dispatch(getAllRoles());        
    }, [])

    
    useEffect(() => {
        if (loading === false) {
            if (resAuth) {
                // console.log("RESULT: ");
                // console.log(resAuth);
                if(resAuth.data.success === true){
                    setTimeout(() => {
                        navigate("/WorkersAccountPage");                    
                    }, 1500);
                        notify(resAuth.data.message, "success");
                }
                else{
                    notify(resAuth.data.message, "error");
                }
            }
        }
    }, [loading])

    return [isProgressOn, name, phone, password, confirmPassword, onChangeName, onChangePhone, onChangePassword, onChangeConfirmPassword, OnSubmit, resRoles, selectedRole, handleSelectRoleChange]
}

export default RegisterHook