
import notify from '../useNotifaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { createNewCategory, deleteCategory, getAllCategories, updateCategory } from '../../redux/actions/categoryAction';

const CategoryHook = () => {
    const [loading, setLoading] = useState(false);
    const [isCreated, setIsCreated] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isUpadte, setisUpadte] = useState(true)

    const resGetCategories = useSelector(state => state.categoryReducer.getAllCategories);
    const resAddCategories = useSelector(state => state.categoryReducer.createNewCategory);
    const resDeleteCategories = useSelector(state => state.categoryReducer.deleteCategory);
    const resUpdateCategories = useSelector(state => state.categoryReducer.updateCategory) 

    const dispatch = useDispatch();

    const [name, setName] = useState('');

    const onChangeName = (e) => {
        setName(e.target.value)
    }

    const isValidationValuesCorrect = () => {
        if (name === "") {
            notify("اسم الصنف مطلوب", "error")
            return false;
        }
        return true;
    }


    useEffect(()=>{
        if(loading === false){
            dispatch(getAllCategories());       
        }
        else{
            if(resAddCategories && resAddCategories !== null){
                
                setIsCreated(true);
                if(resAddCategories.data.success === true){
                    notify(resAddCategories.data.message, "success");
                }
                else{
                    notify(resAddCategories.data.message, "error");
                }
                // dispatch(createNewCategory(null));
            }
            setLoading(false);
        }
    }, [loading])

    const OnSubmit = async () => {
        if (! isValidationValuesCorrect()){
          return;
        }
        setIsCreated(false);
        
        await dispatch(createNewCategory({
            categoryName: name,
            parentCategoryId: null
        }));
        setLoading(true);
        dispatch(getAllCategories());
    }
    



    useEffect(()=>{
        if(isDeleted === true){
            if(resDeleteCategories){
                if(resDeleteCategories.data.success === true){
                    notify(resDeleteCategories.data.message, "success");
                }
                else{
                    notify(resDeleteCategories.data.message, "error");
                }    
            }
            setLoading(false);
        }
        setIsDeleted(false);
    }, [isDeleted]);
    
    const OnDeleteCategory = async(categoryId) => {
        await dispatch(deleteCategory(categoryId));
        setIsDeleted(true);
        setLoading(true);
        dispatch(getAllCategories());
    }
    


    const OnUpdate = async (categoryId,newName) => {
        if (categoryId && newName){
            setisUpadte(false);
            await dispatch(updateCategory({
                id : categoryId,
                categoryName : newName
            }))
            setLoading(true);
            dispatch(getAllCategories());
        } else{
            notify("يجب ادخال اسم الصنف", "error")
        }
    }
    

    useEffect(() => {
        
        if(resUpdateCategories){
            const {success,message} = resUpdateCategories.date;
            notify(message,success? 'success' : 'error');
        }
            
        setisUpadte(true);
        setLoading(false);
    }, [isUpadte])

    return [name, onChangeName, resGetCategories, OnSubmit, isCreated, OnDeleteCategory ,OnUpdate, isUpadte]
}

export default CategoryHook