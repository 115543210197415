import React from 'react';
import { Link } from 'react-router-dom';
import DateTableWithImg from '../../Components/sheardComponent/DateTableWithImg';
import BigCardCOM from '../../Components/sheardComponent/BigCardCOM';
import AddPopupModal from '../../Components/sheardComponent/AddPopupModal';
import FoodHook from '../../hook/foods/food-hook';
import { FixUpdateDataTableHook } from '../../Components/sheardComponent/FixUpdateDataTableHook';
import MainLoaderComp from '../../Components/sheardComponent/MainLoaderComp';
import { ConfirmDeleteComp } from '../../Components/sheardComponent/ConfirmDeleteComp';
import BtnLoaderComp from '../../Components/sheardComponent/BtnLoaderComp';
import { ChangeFreezeCom } from '../../Components/sheardComponent/ChangeFreezeCom';

function ProductsTablePage() {
	const [isCreated, name, description, price, isSpicy, deleted, onChangeName, onChangedescription, onChangeprice, onChangeisSpicy, onChangecategoryId, onChangedeleted, onImageChange, resGetFoods, resCreateFood, resGetCategories, OnSubmit, OnDeleteFood] = FoodHook();

	// تعديه اول شي المتغير لي فيه البيانات حق الداتا تيبل و ثاني برمتر سلكتور الجدول نفسه
	FixUpdateDataTableHook(resGetFoods, '.dom-jQuery-events');

	return (
		<div>
			<BigCardCOM />
			<div className="productsViewSection">
				<div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
					<button
						className="main-btn"
						data-toggle="modal"
						data-target="#AddIconForm"
						style={{ padding: '7px 35px', fontSize: '18px', fontWeight: 'bold' }}
					>
						<span>اضافة طعام</span>
						<div className="svg-wrapper-1">
							<div className="svg-wrapper">
								<i className="fa-solid fa-circle-plus" />
							</div>
						</div>
					</button>

					<div className="btns">
						<Link to="/ProdutsPage" className="btn btn-outline-primary mr-1 mb-1">
							<i className="fa-solid fa-grip" />
						</Link>
						<a href="/ProdutsTablePage" className="btn btn-primary mr-1 mb-1">
							<i className="fa-solid fa-list" />
						</a>
					</div>
				</div>

				<div className="table-responsive my-4">
					{
						resGetFoods != null ? (
							<table className="table table-striped table-bordered dom-jQuery-events">
							<thead>
								<tr>
									<th>
										الصورة
									</th>
									<th>
										الاسم
									</th>
									<th>
										الوصف
									</th>
									<th>
										الصنف
									</th>
									<th>
										مجمد
									</th>
									<th>
										السعر
									</th>
									<th>
										الإجرائات
									</th>
								</tr>
							</thead>
							<tbody>
							{
								// console.log(resGetFoods.data)
							}
								{
									resGetFoods?.data.data.map((item, index) => {
                                        return (
											<tr>
											<td>
												<div className="tableContainerImg " style={{}}>
													<img
														className="w-100 h-100"
														src={item.images && item.images.length > 0 ? `${item.images[0].url}` : '../../app-assets/img/photos/08.jpg'}
														alt="food img"
													/>
												</div>
											</td>
											<td>
												<span>{item.name}</span>
											</td>
											<td>
												<span>{item.description}</span>
											</td>
											<td>
												<span>{item.categoryName}</span>
											</td>
											<td>
												{item.deleted ? (
													<i  className="fa-regular fa-circle-check statusData statusActive freezeIcon" />
												) : (
													<i className="fa-regular fa-circle-xmark statusData statusNActive" />
												)}
											</td>
											<td>
												<span>{item.price}$</span>
											</td>
											<td>
												<div className="groupOptions">
													<button
														className="edit-post gold"
														data-toggle="modal"
														data-target="#EditIconForm"
													>
														<span className="edit-tooltip">تعديل</span>
														<span className="edit-icon">
															<i className="ft-edit-2" />
														</span>
													</button>
													<ChangeFreezeCom onChangeStateFun={OnDeleteFood} nameItem={item.name} idItem={item.id}/>
												</div>
											</td>
										</tr>
                                        );
                                    })
								}
							</tbody>
						</table>
						):(
							<MainLoaderComp/>
						)
					}
				</div>
			</div>

			{
				//AddModel
			}
			<div
				className="modal fade text-left"
				id="AddIconForm"
				tabindex="-1"
				role="dialog"
				aria-labelledby="myModalLabel34"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="myModalLabel34">
								اضافة وجبة
							</h3>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">
									<i className="ft-x font-medium-2 text-bold-700" />
								</span>
							</button>
						</div>
						
							<div className="modal-body">
								<div className="">
									<div className="form-group mb-2">
										<label>اسم الوجبة</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												data-validation-required-message="هذا الحقل مطلوب"
												required
												value={name}
												onChange={onChangeName}
											/>
										</div>
									</div>
									<div className="form-group mb-2">
										<label>وصف الوجبة</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												data-validation-required-message="هذا الحقل مطلوب"
												required
												placeholder=""
												value={description}
												onChange={onChangedescription}
											/>
										</div>
									</div>
									<div className="form-group mb-2">
										<label for="basic-form-6">الصنف</label>
										<select
											id="basic-form-6"
											name="interested"
											className="form-control"
											onChange={onChangecategoryId}
										>
										{
											resGetCategories? (
												resGetCategories.data.data.map((categorie, index) => {
													return (
														<option key={categorie.id} value={categorie.id}>
															{categorie.categoryName}
														</option>
													);
												})
											) : ("لايوجد تنصيفات")
										}
										</select>
									</div>
									<div className="form-row">
										<div className="col-6">
											<div className="form-group mb-2">
												<label>سعر الوجبة</label>
												<div className="controls">
													<input
														type="text"
														name="numeric"
														className="form-control"
														data-validation-containsnumber-regex="(\d)+"
														data-validation-containsnumber-message="هذا الحقل مطلوب الرجاء اخال ارقام فقط"
														placeholder=""
														value={price}
														onChange={onChangeprice}
													/>
												</div>
											</div>
										</div>
										<div className="col-3 m-auto">
											<div className="checkbox checkbox-success">
												<input type="checkbox" id="dash-checkbox5" 														
												value={deleted}
												onChange={onChangedeleted}  />
												<label for="dash-checkbox5">عرض الوجبة</label>
											</div>

										</div>
										<div className="col-3 m-auto">
											<div className="checkbox checkbox-success">
												<input type="checkbox" id="dash-checkbox6" 														
												value={isSpicy}
												onChange={onChangeisSpicy}  />
												<label for="dash-checkbox6">حاره</label>
											</div>

										</div>
									</div>
									<label className="custum-file-upload w-100" for="UplodeFile">
										<div className="icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill=""
												viewBox="0 0 24 24"
											>
												<g stroke-width="0" id="SVGRepo_bgCarrier" />
												<g
													stroke-linejoin="round"
													stroke-linecap="round"
													id="SVGRepo_tracerCarrier"
												/>
												<g id="SVGRepo_iconCarrier">
													{' '}
													<path
														fill=""
														d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
														clip-rule="evenodd"
														fill-rule="evenodd"
													/>{' '}
												</g>
											</svg>
										</div>
										<div className="text">
											<span>انقر لختيار صوره</span>
										</div>
										<input type="file" id="UplodeFile" onChange={onImageChange} />
									</label>
								</div>
							</div>
							<div className="modal-footer">
								<input
									type="reset"
									className="btn bg-light-secondary"
									data-dismiss="modal"
									value="إلغاء"
								/>
								{isCreated ? (
									<button onClick={OnSubmit} className="btn btn-primary">
										تأكيد
										</button>
								) : (<BtnLoaderComp/>) 
								}
							</div>
						
					</div>
				</div>
			</div>

			{
				//EditModel
			}
			<div
				className="modal fade text-left"
				id="EditIconForm"
				tabindex="-1"
				role="dialog"
				aria-labelledby="myModalLabel34"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="myModalLabel34">
								تعديل وجبة
							</h3>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">
									<i className="ft-x font-medium-2 text-bold-700" />
								</span>
							</button>
						</div>
						<form>
							<div className="modal-body">
								<div className="">
									<div className="form-group mb-2">
										<label>اسم الوجبة</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												data-validation-required-message="هذا الحقل مطلوب"
												required
												placeholder=""
											/>
										</div>
									</div>
									<div className="form-group mb-2">
										<label>وصف الوجبة</label>
										<div className="controls">
											<input
												type="text"
												name="text"
												className="form-control"
												data-validation-required-message="هذا الحقل مطلوب"
												required
												placeholder=""
											/>
										</div>
									</div>
									<div className="form-group mb-2">
										<label for="basic-form-6">الصنف</label>
										<select
											id="basic-form-6"
											name="interested"
											className="form-control"
										>
											<option value="none" selected="" disabled="">
												Interested in
											</option>
											<option value="design">Design</option>
											<option value="development">Development</option>
											<option value="illustration">Illustration</option>
											<option value="branding">Branding</option>
											<option value="video">Video</option>
										</select>
									</div>
									<div className="form-row">
										<div className="col-8">
											<div className="form-group mb-2">
												<label>سعر الوجبة</label>
												<div className="controls">
													<input
														type="text"
														name="numeric"
														className="form-control"
														data-validation-containsnumber-regex="(\d)+"
														data-validation-containsnumber-message="هذا الحقل مطلوب الرجاء اخال ارقام فقط"
														placeholder=""
													/>
												</div>
											</div>
										</div>
										<div className="col-3 m-auto">
											<div className="checkbox checkbox-success">
												<input type="checkbox" id="dash-checkbox5" />
												<label for="dash-checkbox5">عرض الوجبة</label>
											</div>

										</div>
									</div>
									<label className="custum-file-upload w-100" for="UplodeFile">
										<div className="icon">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill=""
												viewBox="0 0 24 24"
											>
												<g stroke-width="0" id="SVGRepo_bgCarrier" />
												<g
													stroke-linejoin="round"
													stroke-linecap="round"
													id="SVGRepo_tracerCarrier"
												/>
												<g id="SVGRepo_iconCarrier">
													{' '}
													<path
														fill=""
														d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
														clip-rule="evenodd"
														fill-rule="evenodd"
													/>{' '}
												</g>
											</svg>
										</div>
										<div className="text">
											<span>انقر لختيار صوره</span>
										</div>
										<input type="file" id="UplodeFile" />
									</label>
								</div>
							</div>
							<div className="modal-footer">
								<input
									type="reset"
									className="btn bg-light-secondary"
									data-dismiss="modal"
									value="إلغاء"
								/>
								<button type="submit" className="btn btn-primary">
									تاكيد
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			
		</div>
	);
}

export default ProductsTablePage;
