import React from 'react';
import './ProdutsPage.css';
import FoodCard from '../../Components/sheardComponent/FoodCard';
import FoodCategorySlider from '../../Components/sheardComponent/FoodCategorySlider';
import { Link } from 'react-router-dom';
import BigCardCOM from '../../Components/sheardComponent/BigCardCOM';
import FoodHook from '../../hook/foods/food-hook';
import MainLoaderComp from './../../Components/sheardComponent/MainLoaderComp';
const ProductsPage = () => {
	const [isCreated, name, description, price, isSpicy, deleted, onChangeName, onChangedescription, onChangeprice, onChangeisSpicy, onChangecategoryId, onChangedeleted, onImageChange, resGetFoods, resCreateFood, resGetCategories, OnSubmit, OnDeleteFood] = FoodHook();
	return (
		<div>
			<BigCardCOM />

			<div className="productsViewSection">
				<div className="searchAndBtns d-block text-right d-sm-flex align-items-center justify-content-between">
					<form className="d-flex justify-content-center mb-2 mb-sm-0" action="javascript:;">
						<div className="faq-search-input form-group position-relative w-100 m-0">
							<input
								type="text"
								className="form-control round form-control-lg shadow p-3"
								id="searchbar"
								placeholder="عن ماذا تبحث..."
							/>
							<button
								className="btn btn-primary round position-absolute"
								type="button"
								style={{ left: '7px', top: '7px', padding: '6px' }}
							>
								<span className="d-none">Search</span>
								<i className="ft-search d-block" style={{ fontSize: '20px' }} />
							</button>
						</div>
					</form>

					<div className="btns">
						<Link to="/ProdutsPage" className="btn btn-primary mr-1 mb-1">
							<i className="fa-solid fa-grip" />
						</Link>
						<a href="/ProdutsTablePage" className="btn btn-outline-primary mr-1 mb-1">
							<i className="fa-solid fa-list" />
						</a>
					</div>
				</div>



				<div className="viewProductCards">
					<div className="row">
					{
						resGetFoods != null ? (
							resGetFoods?.data.data.map((item, index) => {
								return (
									<div className="col-6 col-lg-3 col-md-4 col-sm-6 col-phone">
									<div className="card" style={{ height: '476.125px;' }}>
									<div className="card-content">
										<div className="card-body">
											<div className="card-btns">
												<a href="/ProdutsTablePage" className="card-btn">
												<i className="fa-solid fa-ellipsis-vertical"></i>
											</a>
												
												<div className="card-btn card-btn2" style={{height: '32px;'}}>
												<label className="Freeze-btn">
												
												{item.deleted ? (
													<i className="icon-active fa-solid fa-snowflake"></i>
												) : (
													
													<i className="icon-stop fa-regular fa-snowflake"></i>
												)}
												
												
											</label>
												</div>
											</div>
								
											<div className="imgContainer">
												<img
													className="card-img img-fluid rounded"
													src={item.images && item.images.length > 0 ? `${item.images[0].url}` : '../../app-assets/img/photos/08.jpg'}
													alt="Card image cap"
												/>
											</div>
											<h4 className="card-title">{item.name}</h4>
											<p className="card-text mb-2">{item.categoryName}</p>
											<div className="card-priceAndBtn d-flex justify-content-between align-items-center">
												<button className="main-btn">
													<span>اضافة للسلة</span>
													<div className="svg-wrapper-1">
														<div className="svg-wrapper">
															<i className="fa-solid fa-circle-plus" />
														</div>
													</div>
												</button>
												<h4 className="card-price">{item.price}$</h4>
											</div>
										</div>
									</div>
								</div>
								</div>
								)
							})
						):(<MainLoaderComp/>)
					}

					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductsPage;
